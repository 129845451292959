import {
  Login,
  AuthNavigation,
  DashboardNavigation,
  Dashboard,
  Currents,
  Offers,
  Orders,
  Sevkler
} from "./pages";
import { HomeIcon } from "@heroicons/react/24/solid";

const routes = [
  {
    path: "/auth",
    element: AuthNavigation,
    isPrivate: false,
    subRoutes: [
      {
        path: "login",
        element: Login,
      },
      {
        path: "*",
        type: "default",
        name: "",
        isVisible: true,
      },
    ],
  },
  {
    path: "/dashboard",
    element: DashboardNavigation,
    isPrivate: true,
    subRoutes: [
      {
        path: "products",
        name: "Anasayfa",
        element: Dashboard,
        isVisible: true,
        icon: HomeIcon,
      },
      {
        path: "currents",
        name: "Cariler",
        element: Currents,
        isVisible: true,
        icon: HomeIcon,
      },
      {
        path: "offers",
        name: "Teklifler",
        element: Offers,
        isVisible: true,
        icon: HomeIcon,
      },
      {
        path: "orders",
        name: "Siparişler",
        element: Orders,
        isVisible: true,
        icon: HomeIcon,
      },
      {
        path: "sevkler",
        name: "Sevk Edilenler",
        element: Sevkler,
        isVisible: true,
        icon: HomeIcon,
      },
    ],
  },
];

export default routes;
