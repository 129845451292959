import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState, useEffect } from "react";
import useStore from "../hooks/useStore";
import instance from "../lib/client";
import numberToMoney from "../utils/numberToMoney";

export default function ProductDetailModal({
  isOpen,
  setProductDetailModal,
  id,
}) {
  const { layout,auth } = useStore();
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleProductDetail = async () => {
    const { data } = await instance.get(`/product/${id}`);
    setProduct(data.data);
    setLoading(false);
  };

  function closeModal() {
    setProductDetailModal(false);
  }

  useEffect(() => {
    id && isOpen && handleProductDetail();
  }, [isOpen]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-auto  rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="overflow-auto bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Ürün Detayları
                      </h3>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Ürün Adı
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {product?.name}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Ağırlık (kg)
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {product?.agirlik}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            En (cm)
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {product?.en}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Boy (cm)
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {product?.boy}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Yükselik (cm)
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {product?.yukseklik}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Hacim (m3)
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {product?.m3}
                          </dd>
                        </div>
                        {auth.user?.isAdmin && <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Maliyet TL
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {numberToMoney(product?.maliyet)+ ' ' +'TL'}
                          </dd>
                        </div>}

                            {auth.user?.isAdmin &&<div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">
                                Maliyet USD
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {numberToMoney(product?.maliyetUSD)+ ' ' +'USD'}
                              </dd>
                            </div> }

                        {
                          auth.user?.isAdmin && <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">
                                Maliyet EUR
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {numberToMoney(product?.maliyetEUR)+ ' ' +'EUR'}
                              </dd>
                            </div>
                        }
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            KDV
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {product?.kdv + ' %'}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Fiyat TL
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {numberToMoney(product?.price) + ' TL'}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Fiyat USD
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {numberToMoney(product?.priceUSD || 0) + ' USD'}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Fiyat EUR
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {numberToMoney(product?.priceEUR || 0) + ' EUR'}
                          </dd>
                        </div>
                        <div className="sm:col-span-2">
                          <dt className="text-sm font-medium text-gray-500">
                            Açıklama
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {product?.aciklama}
                          </dd>
                        </div>
                        <div className="sm:col-span-2 mb-4">
                          <dt className="text-sm font-medium text-gray-500">
                            Paketler
                          </dt>

                          <dd className="mt-1 text-sm text-gray-900">
                            <div className="grid grid-cols-4 gap-4 ">
                              {product?.packages &&
                                product?.packages.map((paket) => (
                                  <div className="p-2 w-36 h-36 grid grid-cols-2  ">
                                    <div className="flex flex-col  text-left">
                                      <div className="text-sm font-medium text-gray-500">
                                        Ağırlık (kg)
                                      </div>{" "}
                                      <div className="mt-1 text-sm text-gray-900">
                                        {paket.agirlik}
                                      </div>
                                    </div>
                                    <div className="flex flex-col gap-2 text-left">
                                      <div className="text-sm font-medium text-gray-500">
                                        En (cm)
                                      </div>{" "}
                                      <div className="mt-1 text-sm text-gray-900">
                                        {paket.en}
                                      </div>
                                    </div>
                                    <div className="flex flex-col gap-2 text-left">
                                      <div className="text-sm font-medium text-gray-500">
                                        Boy (cm)
                                      </div>{" "}
                                      <div className="mt-1 text-sm text-gray-900">
                                        {paket.boy}
                                      </div>
                                    </div>
                                    <div className="flex flex-col gap-2 text-left">
                                      <div className="text-sm font-medium text-gray-500">
                                        Yükselik (cm)
                                      </div>{" "}
                                      <div className="mt-1 text-sm text-gray-900">
                                        {paket.yukseklik}
                                      </div>
                                    </div>
                                    <div className="flex flex-col gap-2 text-left">
                                      <div className="text-sm font-medium text-gray-500">
                                        Hacim (m3)
                                      </div>{" "}
                                      <div className="mt-1 text-sm text-gray-900">
                                        {paket.m3}
                                      </div>
                                    </div>
                                    <div className="flex flex-col gap-2 text-left">
                                      <div className="text-sm font-medium text-gray-500">
                                        İçerik
                                      </div>{" "}
                                      <div className="mt-1 text-sm text-gray-900">
                                        {paket.icerik}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </dd>
                        </div>
                        <div className="sm:col-span-2 mt-6 mb-6">
                          <dt className="text-sm font-medium text-gray-500">
                            Ürün Fotoğrafları
                          </dt>

                          <dd className="mt-1 text-sm text-gray-900">
                            <div className="grid grid-cols-4 gap-4">
                              {product?.images &&
                                product?.images.map((image) => (
                                  <div className="p-2 w-36 h-36">
                                    <img src={image.url} />
                                  </div>
                                ))}
                            </div>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  </div>
                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Detayları Kapat
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
