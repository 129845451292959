import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { observer } from "mobx-react-lite";
import useStore from "../hooks/useStore";
import instance from "../lib/client";
import numberToMoney from "../utils/numberToMoney";

const OfferDetailModal = observer(({ revizeNo, open, setOpen }) => {
  const { cart, layout } = useStore();
  const cancelButtonRef = useRef(null);
  const [products, setProducts] = useState([]);

  const handleOfferDetail = async () => {
    if (typeof revizeNo === "object") {
      console.log(revizeNo);
      setProducts(revizeNo);
    } else {
      const { data } = await instance.get(
        `/offer/${cart.selectedOffer._id}/${revizeNo}`
      );

      console.log(data.data);
      if (data.status) {
        setProducts(data.data);
      }
    }
  };

  useEffect(() => {
    handleOfferDetail();
  }, [open, setOpen, revizeNo]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Ürünler
                </Dialog.Title>
                <div className="flex flex-col gap-y-6">
                  {products.map((product) => {
                    return (
                      <div className="grid border-b-[2px] !text-xs grid-cols-5">
                        <div className="flex items-center flex-col">
                          <img src={product.image} />
                          <span className="mt-2 border-t-[1px] w-full">
                            {product.name}
                          </span>
                          <span className="border-t-[1px] w-full">
                            Adet: {product.count}
                          </span>
                        </div>
                        <div className="col-span-2 flex gap-y-2 flex-col">
                          <div className="px-2">
                            İskonto 1:{" "}
                            <span className="bg-green-400 px-1 rounded-md">
                              {parseFloat(product.discount1).toFixed(2) + "%"}
                            </span>
                          </div>
                          <div className="px-2">
                            İskonto 2:
                            <span className="bg-green-400 px-1 rounded-md">
                              {product.discount2 + "%"}
                            </span>
                          </div>
                          <div className="px-2">
                            İskonto 3:{" "}
                            <span className="bg-green-400 px-1 rounded-md">
                              {product.discount3 + "%"}
                            </span>
                          </div>
                          <div className="px-2">
                            Kumaş Farkı:{" "}
                            <span className="bg-green-400 px-1 rounded-md">
                              {numberToMoney(product.kumasFarki) +
                                " " +
                                product.currencyUnit}
                            </span>
                          </div>
                          <div className="px-2">
                            Kataforez Farkı:{" "}
                            <span className="bg-green-400 px-1 rounded-md">
                              {numberToMoney(product.kataForez) +
                                " " +
                                product.currencyUnit}
                            </span>
                          </div>
                          <div className="px-2">
                            KDV :{" "}
                            <span className="bg-green-400 px-1 rounded-md">
                              {product.kdv + "%"}
                            </span>
                          </div>
                          <div className="px-2">
                            Toplam Ürün Fiyatı :{" "}
                            <span className="bg-green-400 px-1 rounded-md">
                              {numberToMoney(product.totalPrice) +
                                " " +
                                product.currencyUnit}
                            </span>
                          </div>
                        </div>
                        <div className="col-span-2">
                          <div className="flex flex-col">
                            <label
                              htmlFor="aciklama"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Ahşap/Metal
                            </label>
                            <div className="mt-1">
                              <textarea
                                rows={1}
                                name="aciklama"
                                id="aciklama"
                                className="block w-full p-1 border-[1px] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-xs"
                                value={product.aciklama}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="flex flex-col mt-1">
                            <label
                              htmlFor="aciklama"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Deri/Kumaş
                            </label>
                            <div className="mt-1">
                              <textarea
                                rows={1}
                                name="aciklama"
                                id="aciklama"
                                className="block w-full p-1 border-[1px] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-xs"
                                value={product.derikumas}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="flex flex-col mt-1">
                            <label
                              htmlFor="aciklama"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Açıklama
                            </label>
                            <div className="mt-1">
                              <textarea
                                rows={1}
                                name="aciklama"
                                id="aciklama"
                                className="block w-full p-1 border-[1px] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-xs"
                                value={product.aciklama}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="w-full">
                    <button
                      onClick={() => {
                        layout.setPrintData(
                          products,
                          layout.printOffer?.current,
                          layout.printOffer?.currencyUnit
                        );
                        layout.setPrintPdfModal(true);
                      }}
                      className="p-2 w-full bg-secondary text-white rounded-md"
                    >
                      Cikti Al
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

export default OfferDetailModal;
