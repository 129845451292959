import { toast } from "react-toastify";

const success = (text) =>
  toast.success(text, {
    position: "top-left",
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });

const error = (text) =>
  toast.error(text, {
    position: "top-left",
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });

const info = (text) =>
  toast.info(text, {
    position: "bottom-right",
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });

const notify = ({
  text,
  type = "success",
  time = 5000,
  position = "top-right",
  hideProgressBar = false,
}) => {
  if (type === "success")
    return toast.success(text, {
      position: position,
      autoClose: time,
      hideProgressBar: hideProgressBar,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  else if (type === "error")
    return toast.error(text, {
      position: position,
      autoClose: time,
      hideProgressBar: hideProgressBar,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  else if (type === "error")
    return toast.info(text, {
      position: position,
      autoClose: time,
      hideProgressBar: hideProgressBar,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  else
    return toast(text, {
      position: position,
      autoClose: time,
      hideProgressBar: hideProgressBar,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
};

const notif = { success, error, info };
export default notif;
