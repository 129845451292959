import instance from "../lib/client";
import React, { Fragment, useState, useEffect } from "react";
import useStore from "../hooks/useStore";
import notify from "../utils/notify";
import ConfirmModal from "./ConfirmModal";
import { observer } from "mobx-react-lite";
import numberToMoney from "../utils/numberToMoney";

const CurrentList = observer(() => {
  const { layout, cart, auth } = useStore();
  const [confirmModal, setConfirmModal] = useState(false);
  const [current, setCurrent] = useState([]);
  const [selectedCurrent, setSelectedCurrent] = useState({});
  const [totals,setTotals] = useState({
    alacak:0,
    borc:0
  })


  const handleCurrents = async () => {
    const { data } = await instance.get("/current");
    if (data.status) {
      setCurrent(data.data);
      setTotals({
        alacak:data.totalAlacak,
        borc:data.totalBorc
      })
    }
  };

  useEffect(() => {
    handleCurrents();
  }, [cart.editCurrent, cart.setEditCurrent]);

  const handleSearch = async (e) => {
    const { data } = await instance.get(`/current/?keyWord=${e.target.value}`);
    if (data.status) {
      setCurrent(data.data);
    }
  };

  const handleDelete = async () => {
    if (selectedCurrent) {
      const { data } = await instance.delete(
        `/current/${selectedCurrent?._id}`
      );
      setSelectedCurrent({});
      if (data.status) {
        setConfirmModal(false);
        notify.success("Cari başarıyla silindi");
        handleCurrents();
      } else {
        setConfirmModal(false);
        handleCurrents();
        notify.error(data.message);
      }
    }
  };

  useEffect(() => {
    handleCurrents();
  }, []);

  return (
    <div className="">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Cariler</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none flex items-center">
          <div className="w-40">
            <div className="mt-1 mr-2">
              <input
                onChange={(e) => handleSearch(e)}
                type="email"
                name="email"
                id="email"
                placeholder="Ara..."
                className="block w-full py-2 px-3 rounded-md h-8 border border-gray-400 shadow-sm sm:text-sm outline-none"
              />
            </div>
          </div>
          <button
            onClick={() => layout.setCompanyModal(true)}
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-secondary px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:w-auto"
          >
            Cari Ekle
          </button>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Ünvan
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Borç
                    </th>{" "}
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Alacak
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Adres
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Cari Tipi
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Ülke
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      İl
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      İlçe
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Vergi Dairesi
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Vergi No
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Yetkili
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Mail
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Tel 1
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Tel 2
                    </th>
                    {auth.user?.isAdmin && (
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    )}
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {current.map((item, personIdx) => (
                    <tr
                      key={item._id}
                      className={personIdx % 2 === 0 ? undefined : "bg-gray-50"}
                    >
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {item.unvan}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {(numberToMoney(item.borc) || 0) +
                          " " +
                          (item?.currencyUnit || "TL")}
                      </td>

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {(numberToMoney(item.alacak) || 0) +
                          " " +
                          (item?.currencyUnit || "TL")}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.address}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.currentType}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.country}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {item.city}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.district}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.vergiDairesi}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.vergiNo}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.yetkili}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {item.mail}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.tel}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.tel2}
                      </td>
                      {auth.user?.isAdmin && (
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <a
                            onClick={() => {
                              setSelectedCurrent(item);
                              setConfirmModal(true);
                            }}
                            href="#"
                            className="text-res-600 hover:text-indigo-900"
                          >
                            Sil<span className="sr-only">, {item.unvan}</span>
                          </a>
                        </td>
                      )}
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <a
                          onClick={() => {
                            cart.setEditCurrent(item);
                            layout.setCompanyModal(true);
                          }}
                          href="#"
                          className="text-res-600 hover:text-indigo-900"
                        >
                          Düzenle
                        </a>
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <a
                          onClick={() => {
                            if (cart.selectedCurrent._id === item._id) {
                              cart.setSelectedCurrent({});
                              cart.setCurrencyUnit("TL");
                            } else {
                              cart.setSelectedCurrent(item);
                              cart.setCurrencyUnit(item.currencyUnit || "TL");
                            }
                          }}
                          href="#"
                          className="text-res-600 hover:text-indigo-900"
                        >
                          {cart.selectedCurrent._id === item._id
                            ? "Seçili"
                            : "Seç"}
                        </a>
                      </td>
                    </tr>
                  ))}
                  {auth.user?.isAdmin && <tr
                      className={"bg-gray-50"}
                  >
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      TOPLAMLAR
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      Toplam Borç: {numberToMoney(totals.borc)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      Toplam Alacak: {numberToMoney(totals.alacak)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">

                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">

                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">

                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">

                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">

                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">

                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">

                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">

                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    </td>

                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    </td>



                  </tr>}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <ConfirmModal
        open={confirmModal}
        setOpen={() => {
          setConfirmModal(false);
        }}
        onSubmit={() => handleDelete()}
        onCancel={() => {
          setConfirmModal(false);
          setSelectedCurrent({});
        }}
        firstText={"Cariyi silmek istediginizden eminmisiniz?"}
        secondText={
          selectedCurrent?.name +
          " adli cariyi silmek istediginizden eminmisiniz? Bu cari silindigi anda butun bu cariye bagli butun tekliflerin baglantilari koparilacaktir!"
        }
      />
    </div>
  );
});

export default CurrentList;
