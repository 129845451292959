import { action, makeAutoObservable } from "mobx";
import { makePersistable, isHydrated, hydrateStore } from "mobx-persist-store";

class LayoutStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "LayoutStore",
      properties: [""],
      storage: localStorage,
    });
  }

  revizeListModal = false;
  loading = false;
  categoryModal = false;
  productModal = false;
  productDetailModal = false;
  printPdfModal = false;
  companyModal = false;
  companyTypeModal = false;
  storeModal = false;
  userModal = false;
  products = [];
  filteredProducts = [];
  selectedProduct = "";
  printOffer = {};
  printData = {
    products: [],
    current: {},
    currencyUnit: "",
  };

  setPrintOffer = (payload) => {
    this.printOffer = payload;
  };

  setPrintData = (products, current, currencyUnit) => {
    this.printData = {
      products: products,
      current: current,
      currencyUnit: currencyUnit,
    };
  };

  setPrintPdfModal = (status) => {
    this.printPdfModal = status;
  };

  setRevizeListModal = (status) => {
    this.revizeListModal = status;
  };

  setFilteredProducts = (products) => {
    this.filteredProducts = products;
  };

  setSelectedProduct = (id) => {
    this.selectedProduct = id;
  };

  setProducts = (products) => {
    this.products = products;
  };

  setProductModal = (status) => {
    this.productModal = status;
  };

  setProductDetailModal = (status) => {
    this.productDetailModal = status;
  };

  setCompanyModal = (status) => {
    this.companyModal = status;
  };

  setCompanyTypeModal = (status) => {
    this.companyTypeModal = status;
  };

  setStoreModal = (status) => {
    this.storeModal = status;
  };

  setUserModal = (status) => {
    this.userModal = status;
  };

  setCategoryModal = (status) => {
    this.categoryModal = status;
  };

  setLoading = (status) => {
    this.loading = status;
  };
}

const layoutStore = new LayoutStore();

export default layoutStore;
