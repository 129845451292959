import { Dialog, Transition } from '@headlessui/react'
import {Fragment, useEffect, useState} from 'react'
import numberToMoney from "../utils/numberToMoney";
import instance from "../lib/client";
import notif from "../utils/notify";

const GetPriceModal = ({isOpen,setIsOpen, offerId,orderId,gettedPrice,totalOfferPrice,currencyUnit}) => {
    const [price,setPrice] = useState(0)

    useEffect(() => {
        if(gettedPrice) {
            setPrice(gettedPrice || 0)
        }
    },[offerId,orderId])

    const onSubmit = async () => {
        if(offerId) {
            const {data} = await instance.post(`/offer/update-offer-getted-price/${offerId}`, {
                gettedPrice:price
            })

            if(data.status) {
                notif.success(data.message)
                closeModal()
            }else {
                notif.error(data.message)
            }
        }

        if(orderId) {
            const {data} = await instance.post(`/offer/update-order-getted-price/${orderId}`, {
                gettedPrice:price
            })

            if(data.status) {
                notif.success(data.message)
                closeModal()
            }else {
                notif.error(data.message)
            }
        }
    }


    function closeModal() {
        setPrice(0)
        setIsOpen(false)
    }


    return (
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        Alınan/Kalan
                                    </Dialog.Title>
                                    <div className="mt-6">
                                        <div className={'mt-4'}>
                                            <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                                                Toplam Fiyat
                                            </label>
                                            <div className="relative mt-1 rounded-md border-gray-300 border-[1px]">
                                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                    <span className="text-gray-500 sm:text-sm">{
                                                        currencyUnit === 'TL' ? '₺' : currencyUnit === 'USD' ? '$' : '€'
                                                    }</span>
                                                </div>
                                                <input
                                                    disabled
                                                    type="text"
                                                    name="price"
                                                    value={numberToMoney(totalOfferPrice)}
                                                    id="price"
                                                    className="block w-full py-2 rounded-md focus:outline-none border-gray-500 pl-7 pr-12 sm:text-sm"
                                                    placeholder="0.00"
                                                    aria-describedby="price-currency"
                                                />
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                  <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                    {currencyUnit}
                                                  </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'mt-2'}>
                                            <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                                                Alınan Tutar
                                            </label>
                                            <div className="relative mt-1 rounded-md border-gray-300 border-[1px]">
                                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                    <span className="text-gray-500 sm:text-sm">{
                                                        currencyUnit === 'TL' ? '₺' : currencyUnit === 'USD' ? '$' : '€'
                                                    }</span>
                                                </div>
                                                <input
                                                    type="number"
                                                    name="price"
                                                    value={price}
                                                    onChange={(e) => {

                                                            setPrice(parseFloat(e.target.value))


                                                    }}
                                                    id="price"
                                                    className="block w-full py-2 rounded-md focus:outline-none border-gray-500 pl-7 pr-12 sm:text-sm"
                                                    placeholder="0.00"
                                                    aria-describedby="price-currency"
                                                />
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                  <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                    {currencyUnit}
                                                  </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'mt-2'}>
                                            <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                                                Kalan Tutar
                                            </label>
                                            <div className="relative mt-1 rounded-md border-gray-300 border-[1px]">
                                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                                    <span className="text-gray-500 sm:text-sm">{
                                                        currencyUnit === 'TL' ? '₺' : currencyUnit === 'USD' ? '$' : '€'
                                                    }</span>
                                                </div>
                                                <input
                                                    disabled
                                                    type="text"
                                                    name="price"
                                                    value={numberToMoney(totalOfferPrice - (price|| 0))}
                                                    id="price"
                                                    className="block w-full py-2 rounded-md focus:outline-none border-gray-500 pl-7 pr-12 sm:text-sm"
                                                    placeholder="0.00"
                                                    aria-describedby="price-currency"
                                                />
                                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                                                  <span className="text-gray-500 sm:text-sm" id="price-currency">
                                                    {currencyUnit}
                                                  </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-4 flex flex-row gap-x-4">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-400 text-white px-4 py-2 text-sm font-medium hover:bg-red-500 transition-all focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2"
                                            onClick={closeModal}
                                        >
                                            Kapat
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary text-white px-4 py-2 text-sm font-medium hover:bg-secondary transition-all focus:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2"
                                            onClick={() => onSubmit()}
                                        >
                                            Güncelle
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
    )
}

export default GetPriceModal
