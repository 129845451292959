import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState, useEffect } from "react";
import useStore from "../hooks/useStore";
import { observer } from "mobx-react-lite";
import notify from "../utils/notify";
import instance from "../lib/client";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { Select } from "./index";

const defaultValues = {
  unvan: "",
  tel: "",
  tel2: "",
  mail: "",
  address: "",
  country: "",
  city: "",
  district: "",
  // currentType,
  vergiDairesi: "",
  vergiNo: "",
  yetkili: "",
  borc: 0,
  alacak: 0,
  currencyUnit: "TL",
};

const CompanyModal = observer(() => {
  const { layout, cart } = useStore();
  const [company, setCompany] = useState(defaultValues);
  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [currentTypeList, setCurrentTypeList] = useState([]);

  function closeModal() {
    layout.setCompanyModal(false);
    cart.setEditCurrent({});
    setCompany(defaultValues);
  }

  const onSubmit = async () => {
    if (cart.editCurrent?._id) {
      const { data } = await instance.put(
        `/current/${cart.editCurrent._id}`,
        company
      );

      if (data.status) {
        notify.success(String(data.message));
        cart.setEditCurrent({});
        closeModal();
      } else {
        notify.error(String(data.message));
      }
    } else {
      const { data } = await instance.post("/current", company);

      if (data.status) {
        notify.success(String(data.message));
        closeModal();
      } else {
        notify.error(String(data.message));
      }
    }
  };

  const handleCountry = async () => {
    const { data } = await instance.get("/country");
    setCountryList(data);
  };

  const handleCity = async (country) => {
    const { data } = await instance.get(`/country/states/${country}`);
    setCityList(data);
  };

  const handleDistrict = async (city) => {
    const { data } = await instance.get(
      `/country/cities/${company.country}/${city}`
    );
    setDistrictList(data);
  };

  const handleType = async () => {
    const { data } = await instance.get("/current/type/for-select");
    setCurrentTypeList(data.data);
  };

  useEffect(() => {
    if (cart.editCurrent?._id) {
      setCompany(cart.editCurrent);
    } else {
      setCompany(defaultValues);
    }
  }, [cart.editCurrent, cart.setEditCurrent]);

  useEffect(() => {
    handleCountry();
    handleType();
  }, []);

  return (
    <>
      <Transition appear show={layout.companyModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full h-fit py-6 max-w-6xl  transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className={"grid grid-cols-2 h-full"}>
                    <div className={"flex justify-center h-full"}>
                      <div
                        className={"flex w-full max-w-[350px] flex-col gap-4"}
                      >
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          {cart.editCurrent._id ? "Cari Güncelle" : "Cari Ekle"}
                        </Dialog.Title>

                        <div className="grid grid-cols-5 mt-2 items-center">
                          <label
                            htmlFor="name"
                            className="block text-sm col-span-1 font-medium text-gray-700"
                          >
                            *Ünvan :
                          </label>
                          <input
                            type="name"
                            name="name"
                            id="name"
                            className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                            placeholder=""
                            value={company.unvan}
                            onChange={(e) => {
                              setCompany({
                                ...company,
                                unvan: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="grid grid-cols-5 mt-2 items-center">
                          <label
                            htmlFor="name"
                            className="block text-sm col-span-1 font-medium text-gray-700"
                          >
                            *Adres :
                          </label>
                          <input
                            type="name"
                            name="adres"
                            id="adres"
                            className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                            placeholder=""
                            value={company.address}
                            onChange={(e) => {
                              setCompany({
                                ...company,
                                address: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="grid grid-cols-5 mt-2 items-center">
                          <label
                            htmlFor="name"
                            className="block text-sm col-span-1 font-medium text-gray-700"
                          >
                            *Cari Tipi :
                          </label>
                          <Select
                            value={company.currentType}
                            textPlaceholder={"Seçiniz..."}
                            colliderClassName={"w-full col-span-4"}
                            className={"w-full"}
                            items={currentTypeList}
                            onChange={(e) => {
                              setCompany({
                                ...company,
                                currentType: e,
                              });
                            }}
                          />
                        </div>
                        <div className="grid grid-cols-5 mt-2 items-center">
                          <label
                            htmlFor="name"
                            className="block text-sm col-span-1 font-medium text-gray-700"
                          >
                            *Para Birimi :
                          </label>
                          <Select
                            value={company.currencyUnit}
                            textPlaceholder={"TL"}
                            colliderClassName={"w-full col-span-4"}
                            className={"w-full"}
                            items={[
                              {
                                value: "TL",
                                label: "TL",
                              },
                              {
                                label: "EUR",
                                value: "EUR",
                              },
                              {
                                label: "USD",
                                value: "USD",
                              },
                            ]}
                            onChange={(e) => {
                              setCompany({
                                ...company,
                                currencyUnit: e,
                              });
                            }}
                          />
                        </div>
                        <div className="grid grid-cols-5 mt-2 items-center">
                          <label
                            htmlFor="name"
                            className="block text-sm col-span-1 font-medium text-gray-700"
                          >
                            *Ülke :
                          </label>
                          <Select
                            value={company.country}
                            textPlaceholder={"Seçiniz..."}
                            colliderClassName={"w-full col-span-4"}
                            className={"w-full"}
                            items={countryList}
                            onChange={(e) => {
                              handleCity(e);
                              setCompany({
                                ...company,
                                country: e,
                              });
                            }}
                          />
                        </div>
                        <div className={"flex flex-row justify-between"}>
                          <div className="w-full   mt-2 items-center">
                            <label
                              htmlFor="name"
                              className="block text-sm col-span-1 font-medium text-gray-700 "
                            >
                              İl :
                            </label>

                            <Select
                              value={company.city}
                              textPlaceholder={"Seçiniz..."}
                              colliderClassName={"w-full col-span-4"}
                              className={"w-full"}
                              items={cityList}
                              onChange={(e) => {
                                handleDistrict(e);
                                setCompany({
                                  ...company,
                                  city: e,
                                });
                              }}
                            />
                          </div>
                          <div className="w-full mt-2 items-center ml-2">
                            <label
                              htmlFor="name"
                              className="block text-sm col-span-1 font-medium text-gray-700"
                            >
                              İlçe:
                            </label>

                            <Select
                              value={company.district}
                              textPlaceholder={"Seçiniz..."}
                              colliderClassName={"w-full col-span-4"}
                              className={"w-full"}
                              items={districtList}
                              onChange={(e) => {
                                setCompany({
                                  ...company,
                                  district: e,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className={"flex flex-row justify-between"}>
                          <div className="w-full   mt-2 items-center">
                            <label
                              htmlFor="name"
                              className="block text-sm col-span-1 font-medium text-gray-700 "
                            >
                              Borç :
                            </label>

                            <input
                              type="number"
                              name="borc"
                              id="borc"
                              className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                              placeholder=""
                              value={company.borc}
                              onChange={(e) => {
                                setCompany({
                                  ...company,
                                  borc: Number(e.target.value),
                                });
                              }}
                            />
                          </div>
                          <div className="w-full mt-2 items-center ml-2">
                            <label
                              htmlFor="name"
                              className="block text-sm col-span-1 font-medium text-gray-700"
                            >
                              Alacak:
                            </label>

                            <input
                              type="number"
                              name="alacak"
                              id="alacak"
                              className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                              placeholder=""
                              value={company.alacak}
                              onChange={(e) => {
                                setCompany({
                                  ...company,
                                  alacak: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className={"flex flex-row justify-between"}>
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        ></Dialog.Title>
                        <div
                          className={"p-2 bg-red-50 cursor-pointer"}
                          onClick={() => layout.setCompanyModal(false)}
                        >
                          <XMarkIcon className="w-5 h-5" />
                        </div>
                      </div>
                      <div
                        className={"flex w-full max-w-[350px] flex-col gap-4"}
                      >
                        <div className="grid grid-cols-5 mt-2 items-center">
                          <label
                            htmlFor="name"
                            className="block text-sm col-span-1 font-medium text-gray-700"
                          >
                            Vergi Dairesi :
                          </label>
                          <input
                            type="name"
                            name="vdaire"
                            id="vdaire"
                            className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                            placeholder=""
                            value={company.vergiDairesi}
                            onChange={(e) => {
                              setCompany({
                                ...company,
                                vergiDairesi: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="grid grid-cols-5 mt-2 items-center">
                          <label
                            htmlFor="name"
                            className="block text-sm col-span-1 font-medium text-gray-700"
                          >
                            Vergi No :
                          </label>
                          <input
                            type="name"
                            name="vno"
                            id="vno"
                            className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                            placeholder=""
                            value={company.vergiNo}
                            onChange={(e) => {
                              setCompany({
                                ...company,
                                vergiNo: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="grid grid-cols-5 mt-2 items-center">
                          <label
                            htmlFor="name"
                            className="block text-sm col-span-1 font-medium text-gray-700"
                          >
                            *Yetkili :
                          </label>
                          <input
                            type="name"
                            name="yetkili"
                            id="yetkili"
                            className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                            placeholder=""
                            value={company.yetkili}
                            onChange={(e) => {
                              setCompany({
                                ...company,
                                yetkili: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="grid grid-cols-5 mt-2 items-center">
                          <label
                            htmlFor="name"
                            className="block text-sm col-span-1 font-medium text-gray-700"
                          >
                            *Mail :
                          </label>
                          <input
                            type="name"
                            name="mail"
                            id="mail"
                            className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                            placeholder=""
                            value={company.mail}
                            onChange={(e) => {
                              setCompany({
                                ...company,
                                mail: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="grid grid-cols-5 mt-2 items-center">
                          <label
                            htmlFor="name"
                            className="block text-sm col-span-1 font-medium text-gray-700"
                          >
                            *Tel :
                          </label>
                          <input
                            type="name"
                            name="tel"
                            id="tel"
                            className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                            placeholder=""
                            value={company.tel}
                            onChange={(e) => {
                              setCompany({
                                ...company,
                                tel: e.target.value,
                              });
                            }}
                          />
                        </div>
                        <div className="grid grid-cols-5 mt-2 items-center ml-2">
                          <label
                            htmlFor="name"
                            className="block text-sm col-span-1 font-medium text-gray-700"
                          >
                            Tel 2:
                          </label>
                          <input
                            type="name"
                            name="tel2"
                            id="tel2"
                            className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                            placeholder=""
                            onChange={(e) => {
                              setCompany({
                                ...company,
                                tel2: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <button
                      onClick={() => onSubmit()}
                      type="button"
                      className="flex mb-6 mt-6 col-span-2 w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                    >
                      {cart.editCurrent._id ? "Güncelle" : "Ekle"}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
});

export default CompanyModal;
