import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import useStore from "../hooks/useStore";
import { observer } from "mobx-react-lite";
import ImageUploader from "./ImageUploader";
import notify from "../utils/notify";
import instance from "../lib/client";
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { PhotoIcon, TrashIcon } from "@heroicons/react/24/solid";
import { ConfirmModal, CustomImage, Select } from "./index";

const ImageItem = ({ image, index, product, setProduct }) => {
  const [loading, setLoading] = useState(false);

  return (
    <div className={" border rounded-md relative"}>
      {!loading && (
        <div
          onClick={() => {
            const newImages = product.images.filter((item, i) => i !== index);
            setProduct({
              ...product,
              images: newImages,
            });
          }}
          className={
            "p-2 cursor-pointer bg-red-500 rounded-full absolute right-0 top-0"
          }
        >
          <TrashIcon className={"w-4 h-4 text-white"} />
        </div>
      )}
      {!image.isMain && (
        <div
          onClick={() => {
            let newProductImages = [...product.images].map((d) => {
              return {
                ...d,
                isMain: false,
              };
            });

            newProductImages[index] = {
              ...newProductImages[index],
              isMain: true,
            };

            setProduct({
              ...product,
              images: newProductImages,
            });
          }}
          className={
            "p-2 cursor-pointer bg-green-500 rounded-full absolute right-0 top-10"
          }
        >
          <CheckCircleIcon className={"w-4 h-4 text-white"} />
        </div>
      )}
      <ImageUploader
        hideLabel
        setColLoading={(status) => setLoading(status)}
        image={image.url}
        key={index}
        onChange={(value) => {
          let newProductImages = [...product.images];

          newProductImages[index] = {
            ...newProductImages[index],
            url: value,
          };

          setProduct({
            ...product,
            images: newProductImages,
          });
        }}
      />
    </div>
  );
};

const ProductModal = observer(({}) => {
  const { layout,auth } = useStore();
  const [confirmModal, setConfirmModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({
    name: "",
    category: "",
    price: 0,
    priceUSD:0,
    priceEUR:0,
    kdv: 0,
    maliyet: 0,
    maliyetUSD:0,
    maliyetEUR:0,
    en: 0,
    boy: 0,
    yukseklik: 0,
    agirlik: 0,
    m3: 0,
    aciklama: "",
    packages: [],
    images: [],
  });

  useEffect(() => {
    handleCategories();
  }, []);

  const handleCategories = async () => {
    const { data } = await instance.get("/category/for-select");

    if (data.status) {
      setCategories(data.data);
    } else {
      notify.error(String(data.message));
    }
  };

  function closeModal() {
    layout.setSelectedProduct("");
    layout.setProductModal(false);
    setTimeout(() => {
      setProduct({
        name: "",
        category: "",
        price: 0,
        priceEUR: 0,
        priceUSD: 0,
        kdv: 0,
        maliyet: 0,
        maliyetEUR: 0,
        maliyetUSD: 0,
        en: 0,
        boy: 0,
        yukseklik: 0,
        agirlik: 0,
        m3: 0,
        aciklama: "",
        packages: [],
        images: [],
      });
    }, 500);
  }

  const onSubmit = async () => {
    if (layout.selectedProduct) {
      const { data } = await instance.put(
        `/product/${layout.selectedProduct}`,
          {...product, m3:(product.en * product.boy * product.yukseklik) / 1000000}
      );

      if (data.status) {
        notify.success(String(data.message));
        layout.setProductModal(false);
      } else {
        notify.error(String(data.message));
      }
    } else {
      const { data } = await instance.post("/product", {...product,m3:(product.en * product.boy * product.yukseklik) / 1000000});
      if (data.status) {
        notify.success(String(data.message));
        closeModal();
      } else {
        notify.error(String(data.message));
      }
    }
  };

  const addPackage = () => {
    if (
      product.packages[product?.packages?.length - 1]?.en ||
      product.packages.length === 0
    ) {
      setProduct({
        ...product,
        packages: [
          ...product.packages,
          {
            en: 0,
            boy: 0,
            yükseklik: 0,
            agirlik: 0,
            m3: 0,
            icerik: "",
          },
        ],
      });
    } else {
      notify.error("Önceki paket bilgilerini eksiksiz doldurunuz.");
    }
  };

  const removePackage = (index) => {
    const newPackages = product.packages.filter((item, i) => i !== index);
    setProduct({
      ...product,
      packages: newPackages,
    });
    notify.success("Paket silindi.");
  };

  const handleProduct = async () => {
    setLoading(true);

    const { data } = await instance.get(`/product/${layout.selectedProduct}`);

    if (data.status) {
      setProduct(data.data);
      setLoading(false);
    } else {
      notify.error(String(data.message));
    }
  };

  useEffect(() => {
    if (layout.selectedProduct) handleProduct();
    else setLoading(false);
  }, [layout.selectedProduct]);

  return (
    <>
      <Transition appear show={layout.productModal} as={Fragment}>
        <Dialog as="div" className="relative z-20 " onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto h-full">
            <div className="flex min-h-full h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full h-full max-w-6xl min-h-[80vh] transform overflow-auto rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  {!loading && (
                    <div className={"grid grid-cols-2 h-full"}>
                      <div className={"flex mt-3 justify-center h-full"}>
                        <div
                          className={"flex w-full max-w-[350px] flex-col gap-4"}
                        >
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            Ürün Ekle
                          </Dialog.Title>

                          <div className="grid grid-cols-5 mt-2 items-center">
                            <label
                              htmlFor="name"
                              className="block text-sm col-span-1 font-medium text-gray-700"
                            >
                              Ürün Adı :
                            </label>
                            <input
                              type="name"
                              name="name"
                              id="name"
                              className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                              placeholder=""
                              value={product.name}
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  name: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="grid grid-cols-5 mt-2 items-center">
                            <label
                              htmlFor="name"
                              className="block text-sm col-span-1 font-medium text-gray-700"
                            >
                              Kategori :
                            </label>

                            <Select
                              value={product.category}
                              textPlaceholder={"Seçiniz..."}
                              colliderClassName={"w-full col-span-4"}
                              className={"w-full"}
                              items={categories}
                              onChange={(value) =>
                                setProduct({
                                  ...product,
                                  category: value,
                                })
                              }
                            />
                          </div>
                          <div className="grid grid-cols-5 mt-2 items-center">
                            <label
                              htmlFor="name"
                              className="block text-sm col-span-1 font-medium text-gray-700"
                            >
                              Fiyat TL:
                            </label>

                            <input
                              type={"number"}
                              className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                              placeholder=""
                              value={String(product.price)}
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  price: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="grid grid-cols-5 mt-2 items-center">
                            <label
                                htmlFor="name"
                                className="block text-sm col-span-1 font-medium text-gray-700"
                            >
                              Fiyat EUR:
                            </label>

                            <input
                                type={"number"}
                                className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                                placeholder=""
                                value={String(product.priceEUR)}
                                onChange={(e) => {
                                  setProduct({
                                    ...product,
                                    priceEUR: e.target.value,
                                  });
                                }}
                            />
                          </div>
                          <div className="grid grid-cols-5 mt-2 items-center">
                            <label
                                htmlFor="name"
                                className="block text-sm col-span-1 font-medium text-gray-700"
                            >
                              Fiyat USD:
                            </label>

                            <input
                                type={"number"}
                                className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                                placeholder=""
                                value={String(product.priceUSD)}
                                onChange={(e) => {
                                  setProduct({
                                    ...product,
                                    priceUSD: e.target.value,
                                  });
                                }}
                            />
                          </div>
                          <div className="grid grid-cols-5 mt-2 items-center">
                            <label
                              htmlFor="name"
                              className="block text-sm col-span-1 font-medium text-gray-700"
                            >
                              KDV:
                            </label>

                            <input
                              type={"number"}
                              className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                              placeholder=""
                              value={String(product.kdv)}
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  kdv: e.target.value,
                                });
                              }}
                            />
                          </div>
                          {(!layout.selectedProduct || auth.user.isAdmin) && <div className="grid grid-cols-5 mt-2 items-center">
                            <label
                                htmlFor="name"
                                className="block text-sm col-span-1 font-medium text-gray-700"
                            >
                              Maliyet TL:
                            </label>
                            <input
                                type={"number"}
                                className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                                placeholder=""
                                value={product.maliyet}
                                onChange={(e) => {
                                  setProduct({
                                    ...product,
                                    maliyet: e.target.value,
                                  });
                                }}
                            />
                          </div>}

                          {(!layout.selectedProduct || auth.user.isAdmin) && <div className="grid grid-cols-5 mt-2 items-center">
                            <label
                                htmlFor="name"
                                className="block text-sm col-span-1 font-medium text-gray-700"
                            >
                              Maliyet EUR:
                            </label>
                            <input
                                type={"number"}
                                className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                                placeholder=""
                                value={product.maliyetEUR}
                                onChange={(e) => {
                                  setProduct({
                                    ...product,
                                    maliyetEUR: e.target.value,
                                  });
                                }}
                            />
                          </div>}

                          {
                              (!layout.selectedProduct || auth.user.isAdmin) && <div className="grid grid-cols-5 mt-2 items-center">
                                <label
                                    htmlFor="name"
                                    className="block text-sm col-span-1 font-medium text-gray-700"
                                >
                                  Maliyet USD:
                                </label>
                                <input
                                    type={"number"}
                                    className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                                    placeholder=""
                                    value={product.maliyetUSD}
                                    onChange={(e) => {
                                      setProduct({
                                        ...product,
                                        maliyetUSD: e.target.value,
                                      });
                                    }}
                                />
                              </div>
                          }



                          <div className="grid grid-cols-5 mt-2 items-center">
                            <label
                              htmlFor="name"
                              className="block text-sm col-span-1 font-medium text-gray-700"
                            >
                              En(CM):
                            </label>
                            <input
                              type={"number"}
                              className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                              placeholder=""
                              value={product.en}
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  en: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="grid grid-cols-5 mt-2 items-center">
                            <label
                              htmlFor="name"
                              className="block text-sm col-span-1 font-medium text-gray-700"
                            >
                              Boy(CM):
                            </label>
                            <input
                              type={"number"}
                              className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                              placeholder=""
                              value={product.boy}
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  boy: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="grid grid-cols-5 mt-2 items-center">
                            <label
                              htmlFor="name"
                              className="block text-sm col-span-1 font-medium text-gray-700"
                            >
                              Yükselik(CM):
                            </label>
                            <input
                              type={"number"}
                              className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                              placeholder=""
                              value={product.yukseklik}
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  yukseklik: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="grid grid-cols-5 mt-2 items-center">
                            <label
                              htmlFor="name"
                              className="block text-sm col-span-1 font-medium text-gray-700"
                            >
                              Ağırlık:
                            </label>
                            <input
                              type={"number"}
                              className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                              placeholder=""
                              value={product.agirlik}
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  agirlik: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="grid grid-cols-5 mt-2 items-center">
                            <label
                              htmlFor="name"
                              className="block text-sm col-span-1 font-medium text-gray-700"
                            >
                              Hacim (m3):
                            </label>
                            <input
                              type={"number"}
                              className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                              placeholder=""
                              disabled
                              value={String((product.en * product.boy * product.yukseklik) / 1000000)}
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  m3: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="grid grid-cols-5 mt-2 items-center">
                            <label
                              htmlFor="name"
                              className="block text-sm col-span-1 font-medium text-gray-700"
                            >
                              Açıklama:
                            </label>
                            <textarea
                              type={"textarea"}
                              className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                              placeholder=""
                              value={product.aciklama}
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  aciklama: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="grid grid-cols-5 mt-2 items-center">
                            <label className="block text-sm col-span-1 font-medium text-gray-700">
                              Paketler
                            </label>
                            <button
                              onClick={() => addPackage()}
                              type="button"
                              className="flex mt-auto col-span-2 w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                            >
                              Paket Ekle
                            </button>
                          </div>
                          {product?.packages?.map((paket, index) => (
                            <div key={index} className={"mt-2 mb-4"}>
                              {`${index + 1}. Paket`}{" "}
                              <div className="grid grid-cols-5 mt-2 items-center">
                                <label
                                  htmlFor="name"
                                  className="block text-sm col-span-1 font-medium text-gray-700"
                                >
                                  En:
                                </label>
                                <input
                                  type={"number"}
                                  className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                                  placeholder=""
                                  value={product.packages[index].en}
                                  onChange={(e) => {
                                    const newPackages = product.packages?.map(
                                      (paket, paketIndex) => {
                                        if (paketIndex === index) {
                                          return {
                                            ...paket,
                                            en: e.target.value,
                                          };
                                        }
                                        return paket;
                                      }
                                    );
                                    setProduct({
                                      ...product,
                                      packages: newPackages,
                                    });
                                  }}
                                />
                              </div>
                              <div className="grid grid-cols-5 mt-2 items-center">
                                <label
                                  htmlFor="name"
                                  className="block text-sm col-span-1 font-medium text-gray-700"
                                >
                                  Boy:
                                </label>
                                <input
                                  type={"number"}
                                  className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                                  placeholder=""
                                  value={product.packages[index].boy}
                                  onChange={(e) => {
                                    const newPackages = product.packages.map(
                                      (paket, paketIndex) => {
                                        if (paketIndex === index) {
                                          return {
                                            ...paket,
                                            boy: e.target.value,
                                          };
                                        }
                                        return paket;
                                      }
                                    );
                                    setProduct({
                                      ...product,
                                      packages: newPackages,
                                    });
                                  }}
                                />
                              </div>
                              <div className="grid grid-cols-5 mt-2 items-center">
                                <label
                                  htmlFor="name"
                                  className="block text-sm col-span-1 font-medium text-gray-700"
                                >
                                  Yükselik:
                                </label>
                                <input
                                  type={"number"}
                                  className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                                  placeholder=""
                                  value={product.packages[index].yükseklik}
                                  onChange={(e) => {
                                    const newPackages = product.packages.map(
                                      (paket, paketIndex) => {
                                        if (paketIndex === index) {
                                          return {
                                            ...paket,
                                            yükseklik: e.target.value,
                                          };
                                        }
                                        return paket;
                                      }
                                    );
                                    setProduct({
                                      ...product,
                                      packages: newPackages,
                                    });
                                  }}
                                />
                              </div>
                              <div className="grid grid-cols-5 mt-2 items-center">
                                <label
                                  htmlFor="name"
                                  className="block text-sm col-span-1 font-medium text-gray-700"
                                >
                                  Ağırlık:
                                </label>
                                <input
                                  type={"number"}
                                  className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                                  placeholder=""
                                  value={product.packages[index].agirlik}
                                  onChange={(e) => {
                                    const newPackages = product.packages.map(
                                      (paket, paketIndex) => {
                                        if (paketIndex === index) {
                                          return {
                                            ...paket,
                                            agirlik: e.target.value,
                                          };
                                        }
                                        return paket;
                                      }
                                    );
                                    setProduct({
                                      ...product,
                                      packages: newPackages,
                                    });
                                  }}
                                />
                              </div>
                              <div className="grid grid-cols-5 mt-2 items-center">
                                <label
                                  htmlFor="name"
                                  className="block text-sm col-span-1 font-medium text-gray-700"
                                >
                                  Hacim (m3):
                                </label>
                                <input
                                  type={"number"}
                                  className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                                  placeholder=""
                                  value={product.packages[index].m3}
                                  onChange={(e) => {
                                    const newPackages = product.packages.map(
                                      (paket, paketIndex) => {
                                        if (paketIndex === index) {
                                          return {
                                            ...paket,
                                            m3: e.target.value,
                                          };
                                        }
                                        return paket;
                                      }
                                    );
                                    setProduct({
                                      ...product,
                                      packages: newPackages,
                                    });
                                  }}
                                />
                              </div>
                              <div className="grid grid-cols-5 mt-2 items-center">
                                <label
                                  htmlFor="name"
                                  className="block text-sm col-span-1 font-medium text-gray-700"
                                >
                                  İçerik:
                                </label>
                                <textarea
                                  type={"textarea"}
                                  className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                                  placeholder=""
                                  value={product.packages[index].icerik}
                                  onChange={(e) => {
                                    const newPackages = product.packages.map(
                                      (paket, paketIndex) => {
                                        if (paketIndex === index) {
                                          return {
                                            ...paket,
                                            icerik: e.target.value,
                                          };
                                        }
                                        return paket;
                                      }
                                    );
                                    setProduct({
                                      ...product,
                                      packages: newPackages,
                                    });
                                  }}
                                />
                              </div>
                              <button
                                onClick={() => removePackage(index)}
                                type="button"
                                className="flex mt-2 mb-2 col-span-2 w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                              >
                                Paket'i Sil
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div>
                        <div className={"flex flex-row justify-between"}>
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            Resimler
                          </Dialog.Title>
                          <div
                            className={"p-2 bg-red-50 cursor-pointer"}
                            onClick={() => layout.setProductModal(false)}
                          >
                            <XMarkIcon className="w-5 h-5" />
                          </div>
                        </div>
                        <div className={"grid grid-cols-2 gap-8"}>
                          {product.images.map((image, index) => {
                            return (
                              <ImageItem
                                product={product}
                                setProduct={setProduct}
                                image={image}
                                key={index}
                                index={index}
                              />
                            );
                          })}

                          <button
                            onClick={() =>
                              setProduct({
                                ...product,
                                images: [
                                  ...product.images,
                                  { isMain: false, url: "" },
                                ],
                              })
                            }
                            type="button"
                            className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            <PhotoIcon
                              className={"w-8 h-8 text-gray-800 mx-auto"}
                            />
                            <span className="mt-2 block text-sm font-medium text-gray-900">
                              Yeni Resim Ekle
                            </span>
                          </button>
                        </div>
                      </div>
                      <button
                        onClick={() => onSubmit()}
                        type="button"
                        className="flex mt-auto col-span-2 w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                      >
                        {layout.selectedProduct ? "Güncelle" : "Ekle"}
                      </button>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
});

export default ProductModal;
