import React from "react";
import useStore from "../../hooks/useStore";
import OfferCart from "../../components/OfferCart";
import OrderList from "../../components/OrderList";
import {SevkList} from "../../components";

const Sevkler = () => {
  const { cart } = useStore();

  return (
    <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
      {/* Left column */}
      <div className="grid grid-cols-1 gap-4 lg:col-span-2">
        <section aria-labelledby="section-1-title">
          <h2 className="sr-only" id="section-1-title">
            Section title
          </h2>
          <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="p-6 relative">
              <SevkList />
            </div>
          </div>
        </section>
      </div>

      <div className="grid grid-cols-1 gap-4">
        <section aria-labelledby="section-2-title">
          <h2 className="sr-only" id="section-2-title">
            Section title
          </h2>
          <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="p-6">
              {" "}
              <OfferCart />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Sevkler;
