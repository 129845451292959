import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import useStore from "../hooks/useStore";
import { observer } from "mobx-react-lite";
import notify from "../utils/notify";
import instance from "../lib/client";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { TrashIcon } from "@heroicons/react/24/solid";
import { ConfirmModal, CustomImage } from "./index";

const CompanyTypeModal = observer(() => {
  const { layout } = useStore();
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedCType, setSelectedCType] = useState({
    name: "",
    _id: "",
  });

  const [ctype, setCType] = useState({
    name: "",
  });

  const [ctypes, setCTypes] = useState([]);

  function closeModal() {
    layout.setCompanyTypeModal(false);
  }

  useEffect(() => {
    handleCategories();
  }, []);

  const handleCategories = async () => {
    const { data } = await instance.get("/current/type");

    if (data.status) {
      setCTypes(data.data);
    } else {
      notify.error("Cari tipi yüklenemedi");
    }
  };

  const deleteCategory = async () => {
    const { data } = await instance.delete(
      `/current/type/${selectedCType._id}`
    );

    if (data.status) {
      notify.success("Cari tipi başarıyla silindi");
      setSelectedCType({});
      setConfirmModal(false);
      handleCategories();
    } else {
      notify.error(data.message);
    }
  };

  const onSubmit = async () => {
    if (!ctype.name) {
      notify.error("Cari tipi giriniz!");
    } else {
      const { data } = await instance.post("/current/type", ctype);

      if (data.status) {
        notify.success(data.message);
        setCType({
          name: "",
        });
        handleCategories();
      } else {
        notify.error(data.message);
      }
    }
  };

  return (
    <>
      <Transition appear show={layout.companyTypeModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[400px] min-h-[80vh] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className={" grid grid-cols-1 gap-y-4 h-full"}>
                    <div
                      className={
                        "flex flex-col justify-center h-full m-auto items-center max-w-[400px] gap-y-4"
                      }
                    >
                      <div className={"flex w-full flex-row justify-between"}>
                        <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Cari Tipi Ekle
                        </Dialog.Title>
                        <div
                            className={"p-2 bg-red-50 cursor-pointer"}
                            onClick={() => layout.setCompanyTypeModal(false)}
                        >
                          <XMarkIcon className="w-5 h-5" />
                        </div>
                      </div>
                      <div className="grid grid-cols-5 mt-2 items-center">
                        <label
                          htmlFor="name"
                          className="block mr-4 text-sm col-span-1 font-medium text-gray-700"
                        >
                          Cari Tipi :
                        </label>
                        <input
                          type="name"
                          name="name"
                          id="name"
                          className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                          placeholder=""
                          value={ctype.name}
                          onChange={(e) => {
                            setCType({
                              ...ctype,
                              name: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <button
                        onClick={() => onSubmit()}
                        type="button"
                        className="flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                      >
                        Ekle
                      </button>
                    </div>

                    <div>
                      <div className={"flex flex-row justify-between"}>
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Cari Tipleri
                        </Dialog.Title>

                      </div>
                      <div className={"flex flex-col gap-4 px-4 py-2"}>
                        {ctypes.map((d, i) => {
                          return (
                            <li
                              key={i}
                              className={
                                "flex flex-row items-center py-2 border-b"
                              }
                            >
                              <div className={"ml-4"}>
                                <p className="truncate text-sm font-medium text-secondary">
                                  {d.name}
                                </p>
                              </div>
                              <div
                                onClick={() => {
                                  setSelectedCType(d);
                                  setConfirmModal(true);
                                }}
                                className={
                                  "bg-red-500 cursor-pointer rounded-full flex ml-auto p-2"
                                }
                              >
                                <TrashIcon className={"h-4 w-4 text-white"} />
                              </div>
                            </li>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <ConfirmModal
        open={confirmModal}
        setOpen={() => {
          setConfirmModal(false);
        }}
        onSubmit={() => deleteCategory()}
        onCancel={() => {
          setConfirmModal(false);
          setSelectedCType({});
        }}
        firstText={"Cari tipini silmek istediginizden eminmisiniz?"}
        secondText={
          selectedCType?.name +
          " adli cari tipini silmek istediginizden eminmisiniz? Bu cari tipi silindigi anda butun bu cari tipine bagli butun carilerin baglantilari koparilacaktir!"
        }
      />
    </>
  );
});

export default CompanyTypeModal;
