import { api } from "../lib/client";
import authStore from "../stores/authStore";

const downloadExcelFile = async (setIsDownloading) => {
  let fileName = "attachment; filename=";

  console.log(authStore.accessToken);
  fetch(api + "/export-to-excel/products", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${authStore.accessToken.replace("Bearer ", "")}`,
      "Content-Type": "xlsx",
    },
  })
    .then((response) => {
      fileName = response.headers
        .get("Content-disposition")
        .replace("attachment; filename=", "");

      return response.blob();
    })
    .then((blob) => {
      console.log(blob);
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode.removeChild(link);
      setIsDownloading(false);
    });
};

export default downloadExcelFile;
