import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/20/solid";


const CustomImage = ({ src, className, ...rest }) => {
    let [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <img
                onClick={() => setIsOpen(true)}
                src={src}
                className={"cursor-pointer " + className}
                {...rest}
            />

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={() => setIsOpen(false)}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-70" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all relative">
                                    <div
                                        className="absolute p-2 bg-white rounded-full right-1 top-1 opacity-30 hover:opacity-60 cursor-pointer"
                                        onClick={() => setIsOpen(false)}
                                    >
                                        <XMarkIcon className="w-5 h-5" />
                                    </div>
                                    <img src={src} />
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default CustomImage;
