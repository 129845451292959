import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import useStore from "../hooks/useStore";
import { observer } from "mobx-react-lite";
import ImageUploader from "./ImageUploader";
import notify from "../utils/notify";
import instance from "../lib/client";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { TrashIcon, PencilSquareIcon } from "@heroicons/react/24/solid";
import { ConfirmModal, CustomImage } from "./index";

const CategoryModal = observer(() => {
  const { layout } = useStore();
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    image: "",
    name: "",
    _id: "",
  });
  const [category, setCategory] = useState({
    name: "",
    image: "",
  });
  const [categories, setCategories] = useState([]);

  function closeModal() {
    layout.setCategoryModal(false);
  }

  useEffect(() => {
    handleCategories();
  }, []);

  const handleCategories = async () => {
    const { data } = await instance.get("/category");

    if (data.status) {
      setCategories(data.data);
    } else {
      notify.error("Kategoriler yüklenemedi");
    }
  };

  const deleteCategory = async () => {
    const { data } = await instance.get(
      `/category/delete/${selectedCategory._id}`
    );

    if (data.status) {
      notify.success("Kategori başarıyla silindi");
      setSelectedCategory({});
      setConfirmModal(false);
      handleCategories();
    } else {
      notify.error(data.message);
    }
  };

  const onSubmit = async () => {
    if (!category.name) {
      notify.error("Kategori adı giriniz!");
    } else {
      if (category._id) {
        const { data } = await instance.post(
          `/category/update/${String(category._id)}`,
          category
        );

        if (data.status) {
          notify.success(data.message);
          setCategory({
            name: "",
            image: "",
          });
          handleCategories();
        } else {
          notify.error(data.message);
        }
      } else {
        const { data } = await instance.post("/category", category);

        if (data.status) {
          notify.success(data.message);
          setCategory({
            name: "",
            image: "",
          });
          handleCategories();
        } else {
          notify.error(data.message);
        }
      }
    }
  };

  return (
    <>
      <Transition appear show={layout.categoryModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-6xl min-h-[80vh] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className={" grid grid-cols-2 h-full"}>
                    <div
                      className={
                        "flex flex-col justify-center h-full m-auto items-center max-w-[400px] gap-4"
                      }
                    >
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        {category?._id ? "Kategori Güncelle" : "Kategori Ekle"}
                      </Dialog.Title>
                      <ImageUploader
                        image={category.image}
                        onChange={(src) =>
                          setCategory({
                            ...category,
                            image: src,
                          })
                        }
                      />
                      <div className="grid grid-cols-5 mt-2 items-center">
                        <label
                          htmlFor="name"
                          className="block text-sm col-span-1 font-medium text-gray-700"
                        >
                          Kategori Adı :
                        </label>
                        <input
                          type="name"
                          name="name"
                          id="name"
                          className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                          placeholder=""
                          value={category.name}
                          onChange={(e) => {
                            setCategory({
                              ...category,
                              name: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <button
                        onClick={() => onSubmit()}
                        type="button"
                        className="flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                      >
                        {category?._id ? "Güncelle" : "Ekle"}
                      </button>
                      {category?._id && (
                        <button
                          onClick={() => {
                            setCategory({
                              name: "",
                              image: "",
                            });
                          }}
                          type="button"
                          className="flex w-full justify-center rounded-md border border-transparent bg-red-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                        >
                          {"Güncellemeden Çık"}
                        </button>
                      )}
                    </div>

                    <div>
                      <div className={"flex flex-row justify-between"}>
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Kategoriler
                        </Dialog.Title>
                        <div
                          className={"p-2 bg-red-50 cursor-pointer"}
                          onClick={() => layout.setCategoryModal(false)}
                        >
                          <XMarkIcon className="w-5 h-5" />
                        </div>
                      </div>
                      <div
                        className={
                          "flex flex-col max-h-[80vh] overflow-y-auto gap-4 px-4 py-2"
                        }
                      >
                        {categories.map((d, i) => {
                          return (
                            <li
                              key={i}
                              className={
                                "flex flex-row items-center py-2 border-b"
                              }
                            >
                              <CustomImage
                                className="h-12 w-12 rounded-full"
                                src={d.image}
                                alt=""
                              />

                              <div className={"ml-4"}>
                                <p className="truncate text-sm font-medium text-secondary">
                                  {d.name}
                                </p>
                              </div>
                              <div
                                onClick={() => {
                                  setCategory({ ...d });
                                }}
                                className={
                                  "bg-green-500 cursor-pointer rounded-full flex ml-auto p-2"
                                }
                              >
                                <PencilSquareIcon
                                  className={"h-4 w-4 text-white"}
                                />
                              </div>
                              <div
                                onClick={() => {
                                  setSelectedCategory(d);
                                  setConfirmModal(true);
                                }}
                                className={
                                  "bg-red-500 cursor-pointer rounded-full flex ml-4 p-2"
                                }
                              >
                                <TrashIcon className={"h-4 w-4 text-white"} />
                              </div>
                            </li>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <ConfirmModal
        open={confirmModal}
        setOpen={() => {
          setConfirmModal(false);
        }}
        onSubmit={() => deleteCategory()}
        onCancel={() => {
          setConfirmModal(false);
          setSelectedCategory({});
        }}
        firstText={"Kategori silmek istediginizden eminmisiniz?"}
        secondText={
          selectedCategory?.name +
          " adli kategoriyi silmek istediginizden eminmisiniz? Bu kategori silindigi anda butun bu kategoriye bagli butun urunlerin baglantilari koparilacaktir!"
        }
      />
    </>
  );
});

export default CategoryModal;
