import React from "react";
import authStore from "../stores/authStore";
import layoutStore from "../stores/layoutStore";
import cartStore from "../stores/cartStore";

const storeContext = React.createContext({
  auth: authStore,
  layout: layoutStore,
  cart: cartStore,
});

export default storeContext;
