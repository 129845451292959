import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { PlusIcon } from "@heroicons/react/24/solid";
import useStore from "../hooks/useStore";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const PlusButtun = () => {
  const { auth, layout } = useStore();

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex items-center rounded-full text-white hover:text-gray-200 focus:outline-none focus:ring-0 focus:ring-secondary focus:ring-offset-0 focus:ring-offset-gray-100">
          <span className="sr-only">Open options</span>
          <PlusIcon className="h-7 w-7" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {auth.user?.isAdmin && (
              <Menu.Item>
                {({ active }) => (
                  <span
                    onClick={() => layout.setProductModal(true)}
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    Ürün Ekle
                  </span>
                )}
              </Menu.Item>
            )}
            <Menu.Item>
              {({ active }) => (
                <span
                  onClick={() => layout.setCompanyModal(true)}
                  className={classNames(
                    active
                      ? "bg-gray-100 text-gray-900 cursor-pointer"
                      : "text-gray-700",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  Cari Ekle
                </span>
              )}
            </Menu.Item>
            {auth.user?.isAdmin && (
              <Menu.Item>
                {({ active }) => (
                  <span
                    onClick={() => layout.setCompanyTypeModal(true)}
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    Cari Tipi Ekle
                  </span>
                )}
              </Menu.Item>
            )}
            {auth.user?.isAdmin && (
              <Menu.Item>
                {({ active }) => (
                  <span
                    onClick={() => layout.setStoreModal(true)}
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    Mağaza Ekle
                  </span>
                )}
              </Menu.Item>
            )}
            {auth.user?.isAdmin && (
              <Menu.Item>
                {({ active }) => (
                  <span
                    onClick={() => layout.setUserModal(true)}
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    Kullanıcı Ekle
                  </span>
                )}
              </Menu.Item>
            )}
            {auth.user?.isAdmin && (
              <Menu.Item>
                {({ active }) => (
                  <span
                    onClick={() => layout.setCategoryModal(true)}
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : "text-gray-700",
                      "block px-4 py-2 text-sm"
                    )}
                  >
                    Kategori Ekle
                  </span>
                )}
              </Menu.Item>
            )}
            {auth?.user?.hesap === "admin" && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="submit"
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : "text-gray-700",
                      "block w-full px-4 py-2 text-left text-sm"
                    )}
                  >
                    Kullanıcı Ayarları (Admin)
                  </button>
                )}
              </Menu.Item>
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default PlusButtun;
