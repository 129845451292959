import { action, makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import instance from "../lib/client";
import notify from "../utils/notify";
import moment from "moment";

class CartStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "CartStore",
      properties: [
        "cart",
        "offerName",
        "terminDate",
        "isRevize",
        "selectedCurrent",
        "currencyUnit",
      ],
      storage: localStorage,
    });
  }

  selectedOffer = {};

  isCartOpen = false;
  cart = [];
  isRevize = false;
  selectedCurrent = {};
  selectedProduct = {};
  selectedProductIndex = 0;
  offerSettingsModal = false;
  offerName = "";
  offerDate = moment().format("YYYY-MM-DD");
  terminDate = moment().format("YYYY-MM-DD");
  currencyUnit = "TL";

  editCurrent = {};

  setEditCurrent = (payload) => {
    this.editCurrent = payload;
  };

  declineRevize = () => {
    this.isRevize = false;
    this.selectedCurrent = {};
    this.selectedOffer = {};
    this.cart = [];
    this.offerName = "";
    this.terminDate = "";
    this.currencyUnit = "TL";
  };

  setCart = (cart) => {
    this.cart = cart;
  };

  setIsRevize = (status) => {
    this.isRevize = status;
  };

  setSelectedOffer = (offer) => {
    this.selectedOffer = offer;
  };

  deleteCart = () => {
    this.cart = [];
  };

  changeItemInCard = (key, value) => {
    this.cart[this.selectedProductIndex][key] = value;
    this.calculateProductTotalPrice(this.selectedProductIndex);
  };

  clearCart = () => {
    this.offerName = "";
    this.terminDate = "";
    this.cart = [];
    this.selectedCurrent = {};
    this.selectedProduct = {};
    this.currencyUnit = "TL";
  };

  getProductMaliyet(item, currencyUnit) {
    switch (currencyUnit || this.currencyUnit) {
      case "TL": {
        return item?.price || 0;
      }
      case "USD": {
        return item?.priceUSD || 0;
      }
      case "EUR": {
        return item?.priceEUR || 0;
      }
    }
  }

  getProductPrice(item, currencyUnit) {
    switch (currencyUnit || this.currencyUnit) {
      case "TL": {
        return item?.price || 0;
      }
      case "USD": {
        return item?.priceUSD || 0;
      }
      case "EUR": {
        return item?.priceEUR || 0;
      }
    }
  }

  reverseCalculateProductPrice = (value) => {
    const product = this.cart[this.selectedProductIndex];

    this.changeItemInCard("discount2", 0);
    this.changeItemInCard("discount3", 0);
    this.changeItemInCard("discount4", 0);
    this.changeItemInCard("kataForez", 0);
    this.changeItemInCard("kumasFarki", 0);
    this.changeItemInCard(
      "discount1",
      (((this.getProductPrice(product) * product.count || 0) - Number(value)) /
        (this.getProductPrice(product) * product.count || 0)) *
        100
    );

    return value || product.totalPrice;
  };

  calculateProductTotalPrice = (index) => {
    const product = this.cart[index];

    const bazFiyat =
      (Number(this.getProductPrice(product)) +
        Number(product.kataForez) +
        Number(product.kumasFarki)) *
      Number(product.count);

    const kdv = bazFiyat * (Number(product.kdv) / 100);

    const toplamFiyat = bazFiyat + kdv;

    const discount1 =
      (this.getProductPrice(product) || 0) *
      product.count *
      (product.discount1 / 100);
    const discount2 =
      (this.getProductPrice(product) || 0) *
      product.count *
      (product.discount2 / 100);
    const discount3 =
      (this.getProductPrice(product) || 0) *
      product.count *
      (product.discount3 / 100);

    const totalDiscount = discount1 + discount2 + discount3;

    this.cart[index].totalPrice = toplamFiyat - totalDiscount;

    return toplamFiyat - totalDiscount;
  };

  addToCard = (product) => {
    this.cart = [
      ...this.cart,
      {
        ...product,
        discount1: 0,
        discount2: 0,
        discount3: 0,
        totalPrice: this.getProductPrice(product),
        ahsapmetal: "",
        derikumas: "",
        kdv: product.kdv || 0,
        aciklama: "",
        count: 1,
        kumasFarki: 0,
        kataForez: 0,
      },
    ];
    notify.success(product?.name + " Sepete Eklendi!");
  };

  removeItemFromCart = (index) => {
    this.cart.splice(index, 1);
  };

  addItemToCart = (item) => {
    this.cart.push(item);
  };

  setIsCartOpen = (status) => {
    this.isCartOpen = status;
  };

  setSelectedCurrent = (current) => {
    this.selectedCurrent = current;
  };

  setCurrencyUnit = (unit) => {
    this.currencyUnit = unit;
  };

  setOfferName = (name) => {
    this.offerName = name;
  };

  setTerminDate = (date) => {
    this.terminDate = date;
  };

  setSelectedProduct = (product, index) => {
    this.selectedProduct = product;
    this.selectedProductIndex = index;
  };

  setOfferSettingsModal = (status) => {
    this.offerSettingsModal = status;
  };
}

const cartStore = new CartStore();

export default cartStore;
