import React from "react";
import { useForm } from "react-hook-form";
import instance from "../../lib/client";
import notify from "../../utils/notify";
import useStore from "../../hooks/useStore";
import authService from "../../services/authService";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";

const Login = () => {
  const { auth } = useStore();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
      rememberMe: false,
    },
  });

  const onSubmit = async (body) => {
    const { data } = await instance.post("/user/login", body);
    console.log(data);
    if (!data.success) {
      notify.error(data.message);
    } else {
      const jwtDecoded = await jwtDecode(data.token);
      authService.setAuthorizationToken(data.token);
      auth.setAccessToken(data.token);
      auth.setLogged(true);
      auth.setUser(jwtDecoded);
      navigate("/dashboard/products");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div>
        <label
          htmlFor="username"
          className="block text-sm font-medium text-gray-700"
        >
          Kullanıcı adı
        </label>
        <div className="mt-1">
          <input
            id="username"
            name="username"
            type="username"
            autoComplete="username"
            className={`block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${
              errors.username
                ? "border-red-500 bg-red-200 focus:ring-red-500"
                : ""
            }`}
            {...register("username", { required: true })}
          />
          {errors.username && (
            <span className={" text-xs text-red-500"}>Zorunlu alan</span>
          )}
        </div>
      </div>

      <div>
        <label
          htmlFor="password"
          className="block text-sm font-medium text-gray-700"
        >
          Şifre
        </label>
        <div className="mt-1">
          <input
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            className={`block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm ${
              errors.username
                ? "border-red-500 bg-red-200 focus:ring-red-500"
                : ""
            }`}
            {...register("password", { required: true })}
          />
        </div>
        {errors.password && (
          <span className={" text-xs text-red-500"}>Zorunlu alan</span>
        )}
      </div>

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <input
            id="remember-me"
            name="remember-me"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            {...register("rememberMe")}
          />
          <label
            htmlFor="remember-me"
            className="ml-2 block text-sm text-gray-900"
          >
            Beni Hatırla
          </label>
        </div>
      </div>

      <div>
        <button
          type="submit"
          className="flex w-full justify-center rounded-md border border-transparent bg-[#060A2E] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
        >
          Giriş Yap
        </button>
      </div>
    </form>
  );
};

export default Login;
