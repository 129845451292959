import { Dialog, Transition } from "@headlessui/react";
import { observer } from "mobx-react-lite";
import { Fragment, useState, useRef } from "react";
import useStore from "../hooks/useStore";
import Select from "./Select";
import { useReactToPrint } from "react-to-print";
import PrintScreen from "./PrintScreen";


const PrintPdfModal = observer(() => {
  const [selectedType, setSelectedType] = useState("Müşteri");
  const { layout, cart ,auth} = useStore();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });


  const printTypes = auth.user?.isAdmin ? [
    {
      label: "Müşteri",
      value: "Müşteri",
    },
    {
      label: "Tedarikçi",
      value: "Tedarikçi",
    },
    {
      label: "Admin",
      value: "Admin",
    },
  ]: auth.user.shop === 'operasyon' ? [
    {
      label: "Müşteri",
      value: "Müşteri",
    },{
      label: "Tedarikçi",
      value: "Tedarikçi",
    }
  ] : [
    {
      label: "Müşteri",
      value: "Müşteri",
    }
  ];


  function closeModal() {
    layout.setPrintData([], {}, "");
    layout.setPrintPdfModal(false);
  }

  return (
    <Transition appear show={layout.printPdfModal} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full min-h-[250px] max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Çıktı Tipi Seçiniz
                </Dialog.Title>
                <div className="mt-4">
                  <Select
                    items={printTypes}
                    value={selectedType}
                    onChange={(value) => setSelectedType(value)}
                  />
                </div>

                <div className="sr-only">
                  <div ref={componentRef}>
                    <PrintScreen
                      type={selectedType}
                      products={layout.printData.products}
                      current={layout.printData.current}
                      currencyUnit={layout.printData.currencyUnit}
                      gettedPrice={layout.printOffer?.gettedPrice || 0}
                      sipno={layout.printOffer?.sipno || ''}
                    />
                  </div>
                </div>
                <div className="mt-4 flex flex-row gap-x-2">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-400 px-4 py-2 text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    Kapat
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={handlePrint}
                  >
                    Çıktı Al
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
});

export default PrintPdfModal;
