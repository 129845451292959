import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { observer } from "mobx-react-lite";
import useStore from "../hooks/useStore";
import instance from "../lib/client";
import { XMarkIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import numberToMoney from "../utils/numberToMoney";
import OfferDetailModal from "./OfferDetailModal";

const RevizeSelectModal = observer(() => {
  const { layout, cart } = useStore();
  const [revizes, setRevizes] = useState([]);
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [selectedRevize, setSelectedRevize] = useState(0);

  const onCloseModal = () => {
    layout.setRevizeListModal(false);
  };

  const makeRevize = (offerName, terminDate, current, products) => {
    cart.setCart(products);
    cart.setSelectedCurrent(current);
    cart.setSelectedOffer(cart.selectedOffer);
    cart.setOfferName(offerName);
    cart.setTerminDate(terminDate);
    cart.setIsRevize(true);
    cart.setCurrencyUnit(current.currencyUnit);
    layout.setRevizeListModal(false);
  };

  const handleRevizes = async () => {
    console.log("REVIZES HANDLED");
    const { data } = await instance.get(`/offer/${cart.selectedOffer._id}`);

    console.log(data.data);
    if (data.status) {
      setRevizes(data.data);
    }
  };

  useEffect(() => {
    if (cart.selectedOffer._id) {
      handleRevizes();
    }
  }, [layout.revizeListModal, cart.selectedOffer]);

  return (
    <Transition.Root show={layout.revizeListModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={() => {}}
        onClose={() => onCloseModal()}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="flex mb-4 flex-row items-center justify-between">
                  <Dialog.Title
                    as="h2"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Seçili Teklif Adı: {cart.selectedOffer.name}
                  </Dialog.Title>

                  <div
                    className={"p-1 bg-red-50 cursor-pointer"}
                    onClick={() => layout.setRevizeListModal(false)}
                  >
                    <XMarkIcon className="w-5 h-5" />
                  </div>
                </div>
                <div className="flex flex-row justify-between">
                  <Dialog.Title
                    as="h2"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Revizeler
                  </Dialog.Title>
                  <Dialog.Title
                    as="h2"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Revize Sayısı {revizes.length - 1}
                  </Dialog.Title>
                </div>
                <div className="flex flex-col gap-4 pt-4">
                  {revizes.map((revize, i) => {
                    const isLastRevize = revize.revizeNo === revizes.length - 1;

                    return (
                      <div
                        key={i}
                        className="flex p-2 gap-2 border-[1px] flex-col"
                      >
                        <div className="flex flex-row w-full items-center">
                          <span>
                            {isLastRevize
                              ? "Son Teklif"
                              : "Revize No: " + revize.revizeNo}{" "}
                          </span>
                          <span className="ml-auto">
                            {isLastRevize ? "Teklif Tarihi" : "Revize Tarihi"}:{" "}
                            <span className="font-bold">
                              {moment(revize.revizeDate).format("DD/MM/YYYY")}
                            </span>
                          </span>
                        </div>
                        <div className="flex flex-row w-full items-center">
                          <span>
                            {isLastRevize ? "Teklif Fiyatı" : "Revize Fiyatı"}:{" "}
                            {numberToMoney(revize.offerPrice) +
                              " " +
                              revize.currencyUnit}
                          </span>
                          <span
                            onClick={() => {
                              setSelectedRevize(revize.revizeNo);
                              setIsOpenDetail(true);
                            }}
                            className="ml-auto text-sm bg-primary px-2 py-1 cursor-pointer rounded-md text-white"
                          >
                            Detayları Gör
                          </span>
                          {isLastRevize && (
                            <span
                              onClick={() =>
                                makeRevize(
                                  cart?.selectedOffer?.name,
                                  cart?.selectedOffer?.expireDate,
                                  cart?.selectedOffer?.current,
                                  cart?.selectedOffer?.offers[
                                    cart?.selectedOffer?.offers.length - 1
                                  ].products
                                )
                              }
                              className="ml-2 text-sm bg-primary px-2 py-1 cursor-pointer rounded-md text-white"
                            >
                              Revize Et
                            </span>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
          <OfferDetailModal
            revizeNo={selectedRevize}
            open={isOpenDetail}
            setOpen={setIsOpenDetail}
          />
        </div>
      </Dialog>
    </Transition.Root>
  );
});

export default RevizeSelectModal;
