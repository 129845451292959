import React, { useEffect, useState } from "react";
import useStore from "../../hooks/useStore";
import OfferCart from "../../components/OfferCart";
import { observer } from "mobx-react-lite";
import instance from "../../lib/client";
import notify from "../../utils/notify";
import { SkyBeddMini } from "../../assets/images";
import {
  ConfirmModal,
  CustomImage,
  ProductModal,
  Select,
} from "../../components";
import numberToMoney from "../../utils/numberToMoney";
import ProductDetailModal from "../../components/ProductDetailModal";
import downloadExcelFile from "../../utils/downloadExcelFile";
import Loading from "react-loading";
import FlatList from "flatlist-react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Dashboard = observer(() => {
  const { cart, layout, auth } = useStore();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();
  const [selectedProduct, setSelectedProduct] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [productDetailModal, setProductDetailModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [isHaveMoreItems, setIsHaveMoreItems] = useState(true);
  const [pageSize, setPageSize] = useState(18);
  const [category, setCategory] = useState("all");
  const [keyword, setKeyword] = useState("");
  const [loadingExcel, setLoadingExcel] = useState(false);

  const handleProducts = async () => {
    setLoading(true);
    const { data } = await instance.get(
      `/product?page=${page}&pageSize=${pageSize}`
    );

    if (data.status) {
      setIsHaveMoreItems(data.isHasMoreItems);
      layout.setProducts(data.data);
      layout.setFilteredProducts(data.data);
    } else {
      notify(data.message, "error");
    }
    setLoading(false);
  };

  const handleProductDetail = (product) => {
    layout.setSelectedProduct(product?._id);
    layout.setProductModal(true);
  };

  const seeProductDetails = (product) => {
    setId(product?._id);
    setProductDetailModal(true);
  };

  const handleSearchCategory = async (value) => {
    setCategory(value);
    setLoading(true);
    setPage(1);
    setPageSize(12);
    setIsHaveMoreItems(true);
    const { data } = await instance.get(
      `/product/?keyWord=${keyword}&category=${
        value || category
      }&page=${1}&pageSize=${12}`
    );

    setLoading(false);
    setIsHaveMoreItems(data.isHasMoreItems);
    layout.setFilteredProducts(data.data);
  };

  const handleSearch = async (e) => {
    if (!e.target.value && !loading && !category) {
      setIsHaveMoreItems(true);
      layout.setFilteredProducts(layout.products);
    } else {
      setKeyword(e.target.value);
      setPage(1);

      setPageSize(12);
      setLoading(true);
      const { data } = await instance.get(
        `/product/?keyWord=${
          e.target.value
        }&category=${category}&page=${1}&pageSize=${12}`
      );
      setIsHaveMoreItems(data.isHasMoreItems);
      setLoading(false);
      layout.setFilteredProducts(data.data);
    }
  };

  const loadMoreItems = (e) => {
    console.log("LOAD MORE ITEMS WORKS");
    setPage(page + 1);
    setLoading(true);

    instance
      .get(
        `/product/?keyWord=${keyword}&category=${category}&page=${
          page + 1
        }&pageSize=${pageSize}`
      )
      .then(({ data }) => {
        layout.setFilteredProducts([...layout.filteredProducts, ...data.data]);
        setIsHaveMoreItems(data.isHasMoreItems);

        setLoading(false);
      });
  };

  useEffect(() => {
    handleProducts();
    handleCategories();
  }, []);

  const handleCategories = async () => {
    const { data } = await instance.get("/category");

    if (data.status) {
      setCategories([
        {
          label: "Hepsi",
          value: "all",
        },
        ...data.data.map((d) => {
          return {
            label: d.name,
            value: d._id,
          };
        }),
      ]);
    }
  };

  const deleteProduct = async () => {
    const { data } = await instance.delete(`/product/${selectedProduct._id}`);

    if (data.status) {
      setConfirmModal(false);
      notify.success(data.message);
      handleProducts();
    } else {
      notify.error(data.message);
    }
  };

  const ProductItem = ({ product }) => {
    const [error, setError] = useState(false);
    return (
      <div key={product._id} className="group relative">
        <div className="aspect-w-4 min-h-[230px] max-h-[230px] aspect-h-3 relative overflow-hidden rounded-lg bg-gray-100">
          <LazyLoadImage
            onError={() => setError(true)}
            src={error ? SkyBeddMini : product.image}
            alt={product.name}
            className="object-fit w-full h-full"
          />

          <div
            className="flex flex-col  absolute inset-1 items-end justify-end p-4 opacity-0 group-hover:opacity-100"
            aria-hidden="true"
          >
            <div
              onClick={() => handleProductDetail(product)}
              className="w-full mb-2 z-20 hover:bg-opacity-90 rounded-md bg-primary bg-opacity-30 cursor-pointer py-2 px-4 text-center text-sm font-medium text-gray-900 backdrop-blur backdrop-filter"
            >
              Ürün Güncelle
            </div>

            <div
              onClick={() => seeProductDetails(product)}
              className="w-full mb-2 z-20 hover:bg-opacity-90 rounded-md bg-primary bg-opacity-30 cursor-pointer py-2 px-4 text-center text-sm font-medium text-gray-900 backdrop-blur backdrop-filter"
            >
              Ürün Detay
            </div>
            <div
              onClick={() => cart.addToCard(product)}
              className="w-full z-20 hover:bg-opacity-90 rounded-md bg-primary bg-opacity-30 cursor-pointer py-2 px-4 text-center text-sm font-medium text-gray-900 backdrop-blur backdrop-filter"
            >
              Teklife Ekle
            </div>
            {auth?.user?.isAdmin && (
              <div
                onClick={() => {
                  setSelectedProduct(product);
                  setConfirmModal(true);
                }}
                className="w-full z-20 mt-2 hover:bg-opacity-90 rounded-md bg-red-700 hover:text-white bg-opacity-30 cursor-pointer py-2 px-4 text-center text-sm font-medium text-gray-900 backdrop-blur backdrop-filter"
              >
                Ürünü Sil
              </div>
            )}
          </div>
        </div>
        <div className="mt-4 flex items-center justify-between space-x-8 text-base font-medium text-gray-900">
          <h3>
            <span>
              <span aria-hidden="true" className="absolute inset-0" />
              {product.name}
            </span>
          </h3>
        </div>
        <div className="flex flex-row justify-between">
          <p className="mt-1 text-sm text-gray-500">{product.category.name}</p>
          <p>{numberToMoney(product.price) + " TL"}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
      {/* Left column */}
      <div className="grid grid-cols-1 gap-4 lg:col-span-2">
        <section aria-labelledby="section-1-title">
          <h2 className="sr-only" id="section-1-title">
            Section title
          </h2>

          <div className="overflow-hidden rounded-lg bg-white shadow flex flex-col justify-center items-center">
            <div className="flex flex-row items-center justify-center">
              <div className="w-80 mt-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 "
                >
                  Ara
                </label>
                <div className="mt-1">
                  <input
                    onChange={(e) => handleSearch(e)}
                    type="email"
                    name="email"
                    id="email"
                    className="block w-full py-2 px-3 rounded-md h-8 border border-gray-400 shadow-sm sm:text-sm outline-none"
                  />
                </div>
              </div>
              <div className="w-80 mt-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 "
                >
                  Kategori
                </label>
                <div className="mt-1">
                  <Select
                    value={category}
                    items={categories}
                    onChange={(value) => {
                      handleSearchCategory(value);
                    }}
                  />
                </div>
              </div>
              {auth.user?.isAdmin && (
                <button
                  className="transition-all text-xs ml-auto bg-[#1F2838] text-white px-2 py-1.5 rounded-md cursor-pointer "
                  onClick={() => {
                    if (!loadingExcel) {
                      setLoadingExcel(true);
                      downloadExcelFile(setLoadingExcel);
                    }
                  }}
                >
                  {loadingExcel ? (
                    <Loading type="spin" color="white" width={15} height={15} />
                  ) : (
                    <label>Ürünleri İndir</label>
                  )}
                </button>
              )}
            </div>

            <div className="p-6 relative">
              <div className="mx-auto max-w-2xl lg:max-w-7xl">
                <div className="grid grid-cols-1 overflow-y-auto max-h-[70vh] gap-x-8 gap-y-8 sm:grid-cols-2 sm:gap-y-10 lg:grid-cols-3">
                  <FlatList
                    list={layout.filteredProducts}
                    hasMoreItems={isHaveMoreItems}
                    loadMoreItems={loadMoreItems}
                    renderWhenEmpty={() => {
                      if (isHaveMoreItems) {
                        return <div>{loading ? "Yukleniyor" : "Urun yok"}</div>;
                      } else {
                        return <div></div>;
                      }
                    }}
                    renderItem={(product, i) => (
                      <ProductItem key={i} product={product} />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Right column */}
      <div className="grid grid-cols-1 gap-4">
        <section aria-labelledby="section-2-title">
          <h2 className="sr-only" id="section-2-title">
            Section title
          </h2>
          <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="p-6">
              <OfferCart />
            </div>
          </div>
        </section>
      </div>
      <ProductDetailModal
        isOpen={productDetailModal}
        setProductDetailModal={setProductDetailModal}
        id={id}
      />

      <ConfirmModal
        open={confirmModal}
        setOpen={() => {
          setConfirmModal(false);
        }}
        onSubmit={() => deleteProduct()}
        onCancel={() => {
          setConfirmModal(false);
          setSelectedProduct({});
        }}
        firstText={"Ürünü silmek istediginizden eminmisiniz?"}
        secondText={
          selectedProduct?.name +
          " adli ürünü silmek istediginizden eminmisiniz? Bu ürün silindigi anda butun bu ürüne bagli butun tekliflerin baglantilari koparilacaktir!"
        }
      />
    </div>
  );
});

export default Dashboard;
