import { Fragment, useState } from "react";
import { Menu, Popover, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { MagazinLogo, SelectiveLogo, Skybedd } from "../../assets/images";
import useStore from "../../hooks/useStore";
import PlusButtun from "../../components/PlusButton";
import {
  CategoryModal,
  ProductModal,
  CompanyModal,
  CompanyTypeModal,
  UserModal,
  StoreModal,
  OfferSettingsModal,
} from "../../components";
import RevizeSelectModal from "../../components/RevizeSelectModal";
import { observer } from "mobx-react-lite";
import PrintPdfModal from "../../components/PrintPdfModal";

const user = {
  name: "Tom Cook",
  email: "tom@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};
const navigation = [
  { name: "Ürünler", href: "/dashboard/products", current: false },
  { name: "Cariler", href: "/dashboard/currents", current: false },
  { name: "Teklifler", href: "/dashboard/offers", current: false },
  { name: "Siparişler", href: "/dashboard/orders", current: false },
  { name: "Sevk Edilenler", href: "/dashboard/sevkler", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CurrentView = observer(() => {
  const { cart } = useStore();
  return (
    <div className="text-white">
      <span className="mr-1 font-light">Seçili Cari:</span>
      {cart.selectedCurrent?.unvan
        ? cart.selectedCurrent?.unvan
        : "Seçili Cari Yok"}
    </div>
  );
});

const DashboardNavigation = () => {
  const { auth, cart } = useStore();
  const navigate = useNavigate();
  const [userNavigation] = useState([
    { name: "Çıkış Yap", href: "#", onClick: () => auth.signOut() },
  ]);

  return (
    <>
      <div className="min-h-screen bg-[#070A2C] bg-opacity-80">
        <Popover as="header" className="bg-[#060A2E] pb-24">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="relative flex items-center justify-center py-5 lg:justify-between">
                  {/* Logo */}
                  <div className="absolute left-0 flex-shrink-0 lg:static">
                    <a href="#" className="flex-row flex items-center">
                      <SelectiveLogo className="text-white h-10 mr-auto w-10" />
                      <span className="text-white ml-4 text-xl">SELECTIVE</span>
                    </a>
                  </div>

                  {/* Right section on desktop */}
                  <div className="hidden lg:ml-4 lg:flex lg:items-center lg:pr-0.5">
                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-4 flex-shrink-0">
                      <div className={"flex flex-row gap-6 items-center"}>
                        <PlusButtun className="text-white" color="white" />
                        <Menu.Button className="flex rounded-full bg-white text-sm ring-2 ring-white ring-opacity-20 focus:outline-none focus:ring-opacity-100">
                          <span className="sr-only">Open user menu</span>
                          <div
                            className={
                              "w-8 h-8 rounded-full bg-white flex items-center justify-center"
                            }
                          >
                            <span className={"text-lg"}>
                              {auth.user?.username?.charAt(0).toUpperCase()}
                            </span>
                          </div>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute -right-2 z-10 mt-2 w-48 px-1 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <span
                                  onClick={item.onClick}
                                  className={classNames(
                                    active
                                      ? "bg-gray-200 cursor-pointer text-gray-900"
                                      : "text-black",
                                    "block px-4 py-2 text-sm cursor=pointer"
                                  )}
                                >
                                  {item.name}
                                </span>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>

                  {/* Search */}

                  {/* Menu button */}
                  <div className="absolute right-0 flex-shrink-0 lg:hidden">
                    {/* Mobile menu button */}
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-transparent p-2 text-indigo-200 hover:bg-white hover:bg-opacity-10 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Popover.Button>
                  </div>
                </div>
                <div className="flex justify-between items-center">
                  <div className=" border-t border-white border-opacity-20 py-5 lg:block">
                    <div className="grid grid-cols-3 items-center gap-8">
                      <div className="col-span-2">
                        <nav className="flex space-x-4">
                          {navigation.map((item) => (
                            <NavLink
                              key={item.name}
                              to={item.href}
                              href={item.href}
                              className={({ isActive }) => {
                                return classNames(
                                  isActive
                                    ? "text-black bg-white"
                                    : "text-white",
                                  "text-sm font-medium rounded-md text-white hover:bg-white transition-all hover:bg-opacity-30 px-3 py-2"
                                );
                              }}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </NavLink>
                          ))}
                        </nav>
                      </div>
                    </div>
                  </div>
                  <CurrentView />
                </div>
              </div>

              <Transition.Root as={Fragment}>
                <div className="lg:hidden">
                  <Transition.Child
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="duration-150 ease-in"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Popover.Overlay className="fixed inset-0 z-20 bg-black bg-opacity-25" />
                  </Transition.Child>

                  <Transition.Child
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-150 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Popover.Panel
                      focus
                      className="absolute inset-x-0 top-0 z-30 mx-auto w-full max-w-3xl origin-top transform p-2 transition"
                    >
                      <div className="divide-y divide-gray-200 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="pt-3 pb-2">
                          <div className="flex items-center justify-between px-4">
                            <div>
                              <img
                                className="h-8 w-auto"
                                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                                alt="Your Company"
                              />
                            </div>
                            <div className="-mr-2">
                              <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </Popover.Button>
                            </div>
                          </div>
                          <div className="mt-3 space-y-1 px-2">
                            <a
                              href="#"
                              className="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800"
                            >
                              Home
                            </a>
                            <a
                              href="#"
                              className="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800"
                            >
                              Profile
                            </a>
                            <a
                              href="#"
                              className="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800"
                            >
                              Resources
                            </a>
                            <a
                              href="#"
                              className="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800"
                            >
                              Company Directory
                            </a>
                            <a
                              href="#"
                              className="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800"
                            >
                              Openings
                            </a>
                          </div>
                        </div>
                        <div className="pt-4 pb-2">
                          <div className="flex items-center px-5">
                            <div className="flex-shrink-0">
                              <img
                                className="h-10 w-10 rounded-full"
                                src={user.imageUrl}
                                alt=""
                              />
                            </div>
                            <div className="ml-3 min-w-0 flex-1">
                              <div className="truncate text-base font-medium text-gray-800">
                                {user.name}
                              </div>
                              <div className="truncate text-sm font-medium text-gray-500">
                                {user.email}
                              </div>
                            </div>
                          </div>
                          <div className="mt-3 space-y-1 px-2">
                            {userNavigation.map((item) => (
                              <span
                                key={item.name}
                                onClick={item.onClick}
                                className="block rounded-md px-3 py-2 text-base font-medium text-gray-900 hover:bg-gray-100 hover:text-gray-800"
                              >
                                {item.name}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition.Child>
                </div>
              </Transition.Root>
            </>
          )}
        </Popover>
        <main className="-mt-24 pb-8">
          <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <h1 className="sr-only">Page title</h1>
            {/* Main 3 column grid */}
            <Outlet />
          </div>
        </main>
        <footer>
          <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="border-t text-white border-gray-200 py-8 text-center text-sm sm:text-left">
              <span className="block sm:inline">
                &copy; 2022 Selective Yazılım.
              </span>{" "}
              <span className="block sm:inline">Tüm Hakları Saklıdır.</span>
            </div>
          </div>
        </footer>
      </div>
      <CategoryModal />
      <ProductModal />
      <CompanyModal />
      <CompanyTypeModal />
      <StoreModal />
      <UserModal />
      <OfferSettingsModal />
      <RevizeSelectModal />
      <PrintPdfModal />
    </>
  );
};

export default DashboardNavigation;
