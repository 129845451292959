import { Dialog, Transition,Switch } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import useStore from "../hooks/useStore";
import { observer } from "mobx-react-lite";
import notify from "../utils/notify";
import instance from "../lib/client";
import { XMarkIcon } from "@heroicons/react/20/solid";
import {PencilSquareIcon, TrashIcon} from "@heroicons/react/24/solid";
import { ConfirmModal, Select } from "./index";


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const UserModal = observer(() => {
  const { layout } = useStore();
  const [confirmModal, setConfirmModal] = useState(false);
  const [shops, setShops] = useState([]);
  const [selectedUser, setSelectedUser] = useState({
    username: "",
    password: "",
    shop: "",
    isAdmin:false,
    _id: "",
  });

  const [user, setUser] = useState({
    username: "",
    password: "",
    shop: "",
    isAdmin:false,
  });

  const [users, setUsers] = useState([]);

  function closeModal() {
    layout.setUserModal(false);
  }

  useEffect(() => {
    if (layout.userModal) {
      handleShops();
      handleUsers();
    }
  }, [layout.userModal]);

  const handleShops = async () => {
    const { data } = await instance.get("/shop/for-select");

    if (data.status) {
      setShops(data.data);
    }
  };

  const handleUsers = async () => {
    const { data } = await instance.get("/user");

    if (data.status) {
      setUsers(data.data);
    } else {
      notify.error("Kullanıcılar yüklenemedi");
    }
  };

  const deleteCategory = async () => {
    const { data } = await instance.delete(`/user/${selectedUser._id}`);

    if (data.status) {
      notify.success("Kullanıcı başarıyla silindi");
      setSelectedUser({});
      setConfirmModal(false);
      handleUsers();
    } else {
      notify.error(data.message);
    }
  };

  const onSubmit = async () => {
    if (!user.username) {
      notify.error("Kullanıcı adı giriniz!");
    } else {
      if(user?._id) {
        const { data } = await instance.put(`/user/${user._id}`, user);

        if (data.status) {
          notify.success(data.message);
          setUser({
            username: "",
            password: "",
            shop: "",
            isAdmin:false
          });
          handleUsers();
        } else {
          notify.error(data.message);
        }
      }else {
        const { data } = await instance.post("/user", user);

        if (data.status) {
          notify.success(data.message);
          setUser({
            username: "",
            password: "",
            shop: "",
          });
          handleUsers();
        } else {
          notify.error(data.message);
        }
      }

    }
  };

  return (
    <>
      <Transition appear show={layout.userModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full  max-w-[440px] min-h-[80vh] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className={" grid grid-cols-1 gap-y-4 h-full"}>
                    <div
                      className={
                        "flex flex-col justify-center h-full m-auto max-w-[400px] gap-4"
                      }
                    >
                      <div className={'flex flex-row justify-between items-center'}>
                        <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Kullanıcı Ekle
                        </Dialog.Title>
                        <div
                            className={"p-2 bg-red-50 cursor-pointer"}
                            onClick={() => layout.setUserModal(false)}
                        >
                          <XMarkIcon className="w-5 h-5" />
                        </div>
                      </div>


                      <div className="grid grid-cols-5 mt-2 items-center">
                        <label
                          htmlFor="name"
                          className="block text-sm col-span-1 font-medium text-gray-700"
                        >
                          Kullanıcı Adı:
                        </label>
                        <input
                          type="name"
                          name="name"
                          id="name"
                          className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                          placeholder=""
                          value={user.username}
                          onChange={(e) => {
                            setUser({
                              ...user,
                              username: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="grid grid-cols-5 mt-2 items-center">
                        <label
                          htmlFor="name"
                          className="block text-sm col-span-1 font-medium text-gray-700"
                        >
                          Şifre:
                        </label>
                        <input
                          type="password"
                          name="pass"
                          id="pass"
                          className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
                          placeholder=""
                          value={user.password}
                          onChange={(e) => {
                            setUser({
                              ...user,
                              password: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="grid grid-cols-5 mt-2 items-center">
                        <label
                          htmlFor="name"
                          className="block text-sm col-span-1 font-medium text-gray-700"
                        >
                          Mağaza :
                        </label>

                        <Select
                          value={user.shop}
                          textPlaceholder={"Seçiniz..."}
                          colliderClassName={"w-full col-span-4"}
                          className={"w-full"}
                          items={shops}
                          onChange={(value) =>
                            setUser({
                              ...user,
                              shop: value,
                            })
                          }
                        />
                      </div>
                      <div className="grid grid-cols-5 mt-2 items-center">
                        <label
                            htmlFor="name"
                            className="block text-sm col-span-1 font-medium text-gray-700"
                        >
                          Yetki :
                        </label>

                        <Switch
                            checked={user?.isAdmin}
                            onChange={()=>setUser({
                              ...user,
                              isAdmin:!user.isAdmin
                            })}
                            className={classNames(
                                user?.isAdmin ? 'bg-indigo-600' : 'bg-gray-200',
                                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                            )}
                        >
                          <span className="sr-only">Use setting</span>
                          <span
                              className={classNames(
                                  user.isAdmin? 'translate-x-5' : 'translate-x-0',
                                  'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                              )}
                          >
        <span
            className={classNames(
                user.isAdmin ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200',
                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
            )}
            aria-hidden="true"
        >
          <svg className="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
            <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
          </svg>
        </span>
        <span
            className={classNames(
                user?.isAdmin ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100',
                'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
            )}
            aria-hidden="true"
        >
          <svg className="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
          </svg>
        </span>
      </span>
                        </Switch>
                      </div>

                      <button
                        onClick={() => onSubmit()}
                        type="button"
                        className="flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                      >
                        {user?._id ? 'Güncelle' :'Ekle'}
                      </button>

                      <button
                          onClick={() => {
                            setUser({
                              username: "",
                              password: "",
                              shop: "",
                              isAdmin:false,
                            })
                          }}
                          type="button"
                          className="flex w-full justify-center rounded-md border border-transparent bg-red-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                      >
                        Formu Sıfırla
                      </button>
                    </div>

                    <div>
                      <div className={"flex flex-row justify-between"}>
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          Kullanıcılar
                        </Dialog.Title>

                      </div>
                      <div className={"flex flex-col gap-4 px-4 py-2"}>
                        {users.map((d, i) => {
                          return (
                            <li
                              key={i}
                              className={
                                "flex flex-row items-center py-2 border-b"
                              }
                            >
                              <div className={"ml-4"}>
                                <div>
                                  <p className="truncate text-sm font-medium text-secondary">
                                    {d.username}
                                  </p>
                                  <p className="truncate text-sm font-medium text-secondary">
                                    Mağaza: {d.shop.name}
                                  </p>
                                </div>
                              </div>
                              <div
                                  onClick={() => {
                                    setUser({...d, shop:d.shop?._id});
                                  }}
                                  className={
                                    "bg-green-500 cursor-pointer rounded-full flex ml-auto p-2"
                                  }
                              >
                                <PencilSquareIcon className={"h-4 w-4 text-white"} />
                              </div>
                              <div
                                onClick={() => {
                                  setSelectedUser(d);
                                  setConfirmModal(true);
                                }}
                                className={
                                  "bg-red-500 cursor-pointer rounded-full flex ml-2 p-2"
                                }
                              >
                                <TrashIcon className={"h-4 w-4 text-white"} />
                              </div>
                            </li>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <ConfirmModal
        open={confirmModal}
        setOpen={() => {
          setConfirmModal(false);
        }}
        onSubmit={() => deleteCategory()}
        onCancel={() => {
          setConfirmModal(false);
          setSelectedUser({});
        }}
        firstText={"Kullanıcıyı silmek istediginizden eminmisiniz?"}
        secondText={
          selectedUser?.username +
          " adli kullanıcıyı silmek istediginizden eminmisiniz? Bu kullanıcı silindigi anda silinir!"
        }
      />
    </>
  );
});

export default UserModal;
