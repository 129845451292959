import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import useStore from "../hooks/useStore";
import { observer } from "mobx-react-lite";
import { CustomImage } from "./index";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import numberToMoney from "../utils/numberToMoney";

const CustomInput = ({
  label,
  value,
  onChange,
  key,
  isPercentage,
  ...rest
}) => {
  return (
    <div className="grid grid-cols-5 mt-2 items-center">
      <label
        htmlFor={key}
        className="block text-sm col-span-2 font-medium text-gray-700"
      >
        {label}
      </label>
      {isPercentage ? (
        <div className="mt-1 flex rounded-md col-span-3 w-full shadow-sm">
          <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
            %
          </span>
          <input
            type={key}
            name="pass"
            id="pass"
            className="shadow-sm px-2 py-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
            placeholder=""
            value={value}
            onChange={(e) => onChange(e.target.value)}
            {...rest}
          />
        </div>
      ) : (
        <input
          type={key}
          name="pass"
          id="pass"
          className="shadow-sm px-2 py-2 col-span-3 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
          placeholder=""
          value={value}
          onChange={(e) => onChange(e.target.value)}
          {...rest}
        />
      )}
    </div>
  );
};

const OfferSettingsModal = observer(() => {
  const { cart } = useStore();

  const closeModal = () => {
    cart.setOfferSettingsModal(false);
    setTimeout(() => {
      cart.setSelectedProduct(null, 0);
    }, 400);
  };

  const onChangeValue = (key, value) => {
    cart.changeItemInCard(key, value);
  };

  if (cart.selectedProduct)
    return (
      <>
        <Transition appear show={cart.offerSettingsModal} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Ürün Konfigürasyonları
                    </Dialog.Title>
                    <div className={"grid grid-cols-3 mt-4"}>
                      <div className={"flex flex-col items-center gap-y-4"}>
                        <CustomImage src={cart.selectedProduct?.image} />
                        <span className="mt-auto">Seçili Ürün Adı</span>
                        <span className="mt-2 p-2 bg-primary text-white rounded-md">
                          {cart.selectedProduct?.name}
                        </span>
                        <div
                          className={
                            "flex flex-row mt-4 justify-around w-full items-center"
                          }
                        >
                          <div
                            onClick={() =>
                              onChangeValue(
                                "count",
                                cart.selectedProduct.count - 1 <= 0
                                  ? 1
                                  : cart.selectedProduct.count - 1
                              )
                            }
                            className={
                              "p-1 bg-primary cursor-pointer rounded-md"
                            }
                          >
                            <ChevronLeftIcon
                              color={"white"}
                              style={{
                                width: 25,
                                height: 25,
                              }}
                            />
                          </div>
                          <div className={"text-xl"}>
                            <input
                              className="mx-auto w-10 flex text-center"
                              value={cart.selectedProduct?.count}
                              onChange={(e) => {
                                onChangeValue(
                                  "count",
                                  Number(e.target.value) <= 0
                                    ? 1
                                    : Number(e.target.value)
                                );
                              }}
                            />
                          </div>
                          <div
                            onClick={() =>
                              onChangeValue(
                                "count",
                                cart.selectedProduct.count + 1
                              )
                            }
                            className={
                              "p-1 bg-primary cursor-pointer rounded-md"
                            }
                          >
                            <ChevronRightIcon
                              color={"white"}
                              style={{
                                width: 25,
                                height: 25,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="px-4">
                        <CustomInput
                          label="İskonto 1"
                          value={parseFloat(cart.selectedProduct.discount1).toFixed(2)}
                          key={"discount1"}
                          isPercentage
                          onChange={(value) =>
                            onChangeValue(
                              "discount1",
                              value > 100 ? 100 : Number(value)
                            )
                          }
                          type="number"
                        />
                        <CustomInput
                          label="İskonto 2"
                          value={cart.selectedProduct.discount2}
                          key={"discount2"}
                          isPercentage
                          onChange={(value) =>
                            onChangeValue(
                              "discount2",
                              value > 100 ? 100 : Number(value)
                            )
                          }
                          type="number"
                        />
                        <CustomInput
                          label="İskonto 3"
                          value={cart.selectedProduct.discount3}
                          key={"discount3"}
                          isPercentage
                          onChange={(value) =>
                            onChangeValue(
                              "discount3",
                              value > 100 ? 100 : Number(value)
                            )
                          }
                          type="number"
                        />
                        <CustomInput
                          label="Kumaş Farkı"
                          value={cart.selectedProduct.kumasFarki}
                          key={"kumasFarki"}
                          onChange={(value) =>
                            onChangeValue("kumasFarki", Number(value))
                          }
                          type="number"
                        />
                        <CustomInput
                          label="Kataforez Farkı"
                          value={cart.selectedProduct.kataForez}
                          key={"kataForez"}
                          onChange={(value) =>
                            onChangeValue("kataForez", Number(value))
                          }
                          type="number"
                        />
                        <CustomInput
                          label="KDV"
                          value={cart.selectedProduct.kdv}
                          key={"totalPrice"}
                          isPercentage
                          type="number"
                          onChange={(value) =>
                            onChangeValue(
                              "kdv",
                              value > 100 ? 100 : Number(value)
                            )
                          }
                        />
                        <CustomInput
                          label="Toplam Fiyat"
                          value={
                            numberToMoney(cart.selectedProduct.totalPrice) +
                            " "+ cart.currencyUnit
                          }
                          key={"totalPrice"}
                          disabled={true}
                        />
                        <CustomInput
                            label="Toplam Fiyat'tan İskonto Hesapla"
                            defaulValue={cart.selectedProduct.totalPrice}
                            key={"calculator"}
                            placeholder={Number(cart.selectedProduct.totalPrice)}
                            onChange={(value) => cart.reverseCalculateProductPrice(value)}
                            type={'number'}

                        />
                      </div>
                      <div>
                        <div className="flex flex-col">
                          <label
                            htmlFor="ahsapmetal"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Ahşap/Metal
                          </label>
                          <div className="mt-1">
                            <textarea
                              rows={2}
                              name="ahsapmetal"
                              id="ahsapmetal"
                              className="block p-1 w-full border-[1px] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              value={cart.selectedProduct.ahsapmetal}
                              onChange={(e) =>
                                onChangeValue("ahsapmetal", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="flex flex-col mt-1">
                          <label
                            htmlFor="derikumas"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Deri/Kumaş
                          </label>
                          <div className="mt-1">
                            <textarea
                              rows={2}
                              name="derikumas"
                              id="derikumas"
                              className="block w-full p-1 border-[1px] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              value={cart.selectedProduct.derikumas}
                              onChange={(e) =>
                                onChangeValue("derikumas", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="flex flex-col mt-1">
                          <label
                            htmlFor="aciklama"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Açıklama
                          </label>
                          <div className="mt-1">
                            <textarea
                              rows={2}
                              name="aciklama"
                              id="aciklama"
                              className="block w-full p-1 border-[1px] rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              value={cart.selectedProduct.aciklama}
                              onChange={(e) =>
                                onChangeValue("aciklama", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className=" mt-4 rounded-md">
                          <button
                            onClick={() => closeModal()}
                            className="mt-auto bg-red-400 cursor-pointer items-center justify-center text-white p-1 w-full flex rounded-md"
                          >
                            Kapat
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </>
    );
});

export default OfferSettingsModal;
