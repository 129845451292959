import React from "react";
import { useState } from "react";
import useStore from "../hooks/useStore";
import { observer } from "mobx-react-lite";
// import {CustomImage} from "./index";
import numberToMoney from "../utils/numberToMoney";
import instance from "../lib/client";
import notif from "../utils/notify";
import moment from "moment";
import Select from "./Select";

const CartItem = observer(({ item, index }) => {
  const { cart } = useStore();

  const handleSettings = () => {
    cart.setOfferSettingsModal(true);
    cart.setSelectedProduct(item, index);
  };

  return (
    <div
      className={
        "flex items-center justify-center flex-row py-2 border-b-[1px]"
      }
    >
      <img className={"w-12 h-12 my-auto rounded-full"} src={item.image} />
      <div className={"flex flex-col gap-2 justify-center"}>
        <span className={"text-sm ml-2"} key={index}>
          {item.name}
        </span>
        <span
          className={"text-sm bg-primary pl-2 bg-opacity-60 rounded-md ml-2"}
          key={index}
        >
          {numberToMoney(cart.calculateProductTotalPrice(index)) + " " + cart.currencyUnit}
        </span>
      </div>
      <div className={"ml-auto flex flex-col gap-2"}>
        <button
          onClick={() => {
            cart.removeItemFromCart(index);
          }}
          className={"text-xs ml-auto bg-red-500 p-1 rounded-md text-white"}
        >
          Çıkart
        </button>
        <button
          onClick={() => handleSettings()}
          className={"text-xs ml-auto bg-primary p-1 rounded-md text-white"}
        >
          Konfigure Et
        </button>
      </div>
    </div>
  );
});

const OfferCart = observer(() => {
  const { cart } = useStore();

  const createOffer = async () => {
    if (!cart.isRevize) {
      const { data } = await instance.post("/offer", {
        name: cart.offerName,
        expireTime: cart.terminDate,
        current: cart.selectedCurrent,
        offers: cart.cart,
        currencyUnit: cart.currencyUnit,
      });

      if (data.status) {
        notif.success(data.message);
        cart.clearCart();
      } else {
        notif.error(data.message);
      }
    } else {
      const { data } = await instance.put(
        `/offer/revize/${cart.selectedOffer._id}`,
        {
          products: cart.cart,
        }
      );

      if (data.status) {
        notif.success(data.message);
        cart.declineRevize();
      }
    }
  };

  return (
    <div className={"flex flex-col"}>
      <div className="flex flex-col gap-2 ">
        <label
          htmlFor="name"
          className="block text-sm col-span-1 font-medium text-gray-700"
        >
          Teklif Adı :
        </label>
        <input
          type="name"
          name="yetkili"
          id="yetkili"
          disabled={cart.isRevize ? true : false}
          className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
          placeholder=""
          value={cart.offerName}
          onChange={(e) => {
            cart.setOfferName(e.target.value);
          }}
        />

        <label
          htmlFor="name"
          className="block text-sm col-span-1 font-medium text-gray-700"
        >
          Termin Tarihi :
        </label>
        <input
          type="date"
          name="yetkili"
          id="yetkili"
          className="shadow-sm px-2 py-2 col-span-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border-[1px]"
          placeholder=" "
          value={moment(cart.terminDate).format("YYYY-MM-DD")}
          onChange={(e) => {
            cart.setTerminDate(e.target.value);
          }}
        />
        {/*<label*/}
        {/*  htmlFor="name"*/}
        {/*  className="block text-sm col-span-1 font-medium text-gray-700"*/}
        {/*>*/}
        {/*  Para Birimi :*/}
        {/*</label>*/}
        {/*<Select*/}
        {/*  value={cart.currencyUnit}*/}
        {/*  onChange={(value) => cart.setCurrencyUnit(value)}*/}
        {/*  textPlaceholder={"TL"}*/}
        {/*  items={[*/}
        {/*    {*/}
        {/*      label: "TL",*/}
        {/*      value: "TL",*/}
        {/*    },*/}
        {/*    {*/}
        {/*      label: "EUR",*/}
        {/*      value: "EUR",*/}
        {/*    },*/}
        {/*    {*/}
        {/*      label: "USD",*/}
        {/*      value: "USD",*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*/>*/}
      </div>
      {cart?.cart?.length <= 0 ? (
        <div>
          <button
            type="button"
            className="relative block w-full mt-6 cursor-default rounded-lg border-2 border-dashed border-gray-300 p-12 text-center focus:outline-none"
          >
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M8 14v20c0 4.418 7.163 8 16 8 1.381 0 2.721-.087 4-.252M8 14c0 4.418 7.163 8 16 8s16-3.582 16-8M8 14c0-4.418 7.163-8 16-8s16 3.582 16 8m0 0v14m0-4c0 4.418-7.163 8-16 8S8 28.418 8 24m32 10v6m0 0v6m0-6h6m-6 0h-6"
              />
            </svg>
            <span className="mt-2 block text-sm font-medium text-gray-900">
              Teklife ürün ekle
            </span>
          </button>
        </div>
      ) : (
        <div className={"flex flex-col gap-y-2 mt-4"}>
          {cart.cart.map((item, index) => {
            return (
              <CartItem item={item} index={index} cart={cart} key={index} />
            );
          })}
          <div className={"flex flex-col items-center mt-4"}>
            <span className={"text-gray-500 text-sm mb-1"}>Toplam</span>
            <span className={"text-md font-semibold"}>
              {String(
                numberToMoney(
                  cart?.cart?.reduce((a, b) => (a += b.totalPrice), 0)
                )
              ) +
                " " +
                cart.currencyUnit}
            </span>
          </div>

          <button
            onClick={() => {
              createOffer();
            }}
            className="bg-primary rounded-md mt-2 py-1 text-white"
          >
            {cart.isRevize ? "Revize Et" : "Teklif Oluştur"}
          </button>
          {cart.isRevize && (
            <button
              onClick={() => {
                cart.declineRevize();
              }}
              className="bg-secondary rounded-md py-1 text-white"
            >
              Revizeyi İptal Et
            </button>
          )}
        </div>
      )}
    </div>
  );
});

export default OfferCart;
