import { Dialog, Transition, RadioGroup } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";

const offerTypes = [
  {
    name: "Üretim",
    value: "Üretim",
  },
  {
    name: "Depoda",
    value: "Depoda",
  },
  {
    name: "Sevk Edildi",
    value: "Sevk Edildi",
  },
];

const StatusUpdateModal = ({
  isOpen,
  setIsOpen,
  updateStatus,
  selectedOffer,
}) => {
  const [selected, setSelected] = useState({
    name: "Üretim",
    value: "Üretim",
  });
  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    setSelected({
      name: selectedOffer.status,
      value: selectedOffer.status,
    });
  }, [selectedOffer]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-md font-medium leading-6 text-gray-900"
                >
                  {selectedOffer.name + " Teklifinin Durumunu Güncelle"}
                </Dialog.Title>
                <div className="mt-4">
                  <RadioGroup
                    value={selected}
                    onChange={(value) => {
                      console.log(value);
                      setSelected(value);
                    }}
                  >
                    <RadioGroup.Label className="sr-only">
                      Pricing plans{" "}
                    </RadioGroup.Label>
                    <div className="relative -space-y-px rounded-md bg-white">
                      {offerTypes.map((plan, planIdx) => (
                        <RadioGroup.Option
                          key={plan.name}
                          value={plan}
                          className={({ checked }) =>
                            classNames(
                              planIdx === 0
                                ? "rounded-tl-md rounded-tr-md"
                                : "",
                              planIdx === offerTypes.length - 1
                                ? "rounded-bl-md rounded-br-md"
                                : "",
                              selected.value === plan.value
                                ? "bg-indigo-50 border-indigo-200 z-10"
                                : "border-gray-200",
                              "relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none"
                            )
                          }
                        >
                          {({ active, checked }) => (
                            <>
                              <span className="flex items-center text-sm">
                                <span
                                  className={classNames(
                                    selected.value === plan.value
                                      ? "bg-indigo-600 border-transparent"
                                      : "bg-white border-gray-300",

                                    "h-4 w-4 rounded-full border flex items-center justify-center"
                                  )}
                                  aria-hidden="true"
                                >
                                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                                </span>
                                <RadioGroup.Label
                                  as="span"
                                  className={classNames(
                                    checked
                                      ? "text-indigo-900"
                                      : "text-gray-900",
                                    "ml-3 font-medium"
                                  )}
                                >
                                  {plan.name}
                                </RadioGroup.Label>
                              </span>
                              <RadioGroup.Description
                                as="span"
                                className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center"
                              ></RadioGroup.Description>
                              <RadioGroup.Description
                                as="span"
                                className={classNames(
                                  checked ? "text-indigo-700" : "text-gray-500",
                                  "ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right"
                                )}
                              >
                                {plan.name}
                              </RadioGroup.Description>
                            </>
                          )}
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={() => {
                      updateStatus(selected.value);
                    }}
                  >
                    Güncelle
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default StatusUpdateModal;
