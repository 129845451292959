import instance from "../lib/client";
import React, { Fragment, useState, useEffect } from "react";
import useStore from "../hooks/useStore";
import notify from "../utils/notify";
import { observer } from "mobx-react-lite";
import moment from "moment";
import numberToMoney from "../utils/numberToMoney";
import ConfirmModal from "./ConfirmModal";
import {GetPriceModal, Select} from "./index";

const OfferList = observer(() => {
  const { layout, cart, auth } = useStore();
  const [current, setCurrent] = useState([]);
  const [totals,setTotals] = useState({
    maliyet:0,
    price:0
  })
  const [selectedOffer, setSelectedOffer] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [users,setUsers] = useState([])
  const [selectedUserFilter,setSelectedUserFilter] = useState('all')
  const [isPriceModalOpen, setIsPriceModalOpen] = useState(false);
  const [currencyFilter,setCurrencyFilter] = useState('all')
  const [startTime,setStartTime] =  useState(moment().startOf('year').format('YYYY-MM-DD'));
  const [endTime,setEndTime] = useState(moment().endOf('year').format('YYYY-MM-DD'))
  const [confirmModalDelete,setConfirmModalDelete] = useState(false)

  const handleCurrents = async () => {
    const { data } = await instance.get("/offer");
    if (data.status) {
      setTotals(data.totals)
      console.log(data.data);
      setCurrent(data.data);
    }
  };

  const makeOrder = async () => {
    if (selectedOffer._id) {
      const { data } = await instance.post(`/offer/order/${selectedOffer._id}`);

      if (data.status) {
        notify.success(data.message);
        setConfirmModal(false);
        handleCurrents();
        setSelectedOffer({});
      } else {
        notify.error(data.message);
      }
    }
  };

  const handleUsers = async () =>{
    const {data}= await instance.get('/user/for-select')

    if(data.status){
        setUsers([{
          label:'Hepsi',
          value:'all'
        }, ...data.data])
    }
  }

  const handleSearch = async (e,filter,currencyFilter,start,end) => {
    const { data } = await instance.get(`/offer/?keyWord=${e?.target?.value || 'all'}&userFilter=${filter || selectedUserFilter}&currencyUnit=${currencyFilter}&startTime=${start||startTime}&endTime=${end||endTime}`);
    if (data.status) {
      setTotals(data.totals)
      setCurrent(data.data);
    }
  };

  const handleDelete = async () => {
    const { data } = await instance.delete(`/offer/${selectedOffer._id}`);
    if (data.status) {
      notify.success("Teklif başarıyla silindi");
      setSelectedOffer({})
      setConfirmModalDelete(false)
      handleCurrents();
    } else {
      notify.error(data.message);
    }
  };

  const makeRevize = (offerName, terminDate, current, products, offer) => {
    cart.setCart(products);
    cart.setSelectedCurrent(current);
    cart.setSelectedOffer(offer);
    cart.setOfferName(offerName);
    cart.setTerminDate(terminDate);
    cart.setIsRevize(true);
    cart.setCurrencyUnit(offer.currencyUnit);
  };

  useEffect(() => {
    handleCurrents();
    handleUsers()
  }, []);

  return (
    <div className="">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold text-gray-900">Teklifler</h1>
        </div>
        <div className="mt-4 flex flex-col gap-2 w-full sm:mt-0 sm:ml-16 sm:flex-none flex items-center">
          <div className={'flex gap-4 w-full flex-row'}>
            <div className={'mr-2 flex flex-row items-center'}>
              <span className={'text-sm w-[180px] '}>Baslangic Tarihi:</span><input value={startTime} onChange={(e)=> {

              setStartTime(e.target.value)
              handleSearch('', '','',e.target.value)
            }} type={'date'} className="block w-full mt-1 py-2 px-3 rounded-md h-8 border border-gray-400 shadow-sm sm:text-sm outline-none" />
            </div>
            <div className={'mr-2 flex items-center flex-row'}>
              <span className={'text-sm w-[120px]'}>Bitis Tarihi:</span><input value={endTime} onChange={(e)=> {

              setEndTime(e.target.value)
              handleSearch('', '','','',e.target.value)
            }} type={"date"} className="block w-full mt-1 py-2 px-3 rounded-md h-8 border border-gray-400 shadow-sm sm:text-sm outline-none" />
            </div>
          </div>
          <div className="flex flex-row">

            <div className="mt-1 mr-2 flex flex-row gap-2 items-center">
              <span className={'text-sm'}>Kur:</span>
              <Select
                  onChange={(e) => {
                    setCurrencyFilter(e)
                    handleSearch('', '',e)
                  }}
                  items={[
                    {
                      label:'Hepsi',
                      value:'all'
                    },
                    {
                      label:'TL',
                      value:'TL'
                    },
                    {
                      label:'USD',
                      value:'USD'
                    },
                    {
                      label:'EUR',
                      value:'EUR'
                    }
                  ]}
                  name="email"
                  value={currencyFilter}
                  id="email"
                  textPlaceholder="Para Birimi..."
                  className="block w-full py-2 px-3 rounded-md h-8 border border-gray-400 shadow-sm sm:text-sm outline-none"
              />
            </div>
            <div className="mt-1 mr-2 flex flex-row gap-2 ml-4 items-center">
              <span className={'text-sm'} >Oluşturan Kişi:</span>
              <Select
                  onChange={(e) => {
                    setSelectedUserFilter(e)
                    handleSearch('', e)
                  }}
                  items={users}
                  name="email"
                  value={selectedUserFilter}
                  id="email"
                  textPlaceholder="Ara..."
                  className="block w-full py-2 px-3 rounded-md h-8 border border-gray-400 shadow-sm sm:text-sm outline-none"
              />
            </div>
            <div className="mt-1 mr-2">
              <input
                onChange={(e) => handleSearch(e)}
                type="email"
                name="email"
                id="email"
                placeholder="Ara..."
                className="block w-full py-2 px-3 rounded-md h-8 border border-gray-400 shadow-sm sm:text-sm outline-none"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Teklif Adı
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Müşteri Ünvanı
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Termin Tarihi
                    </th>
                    <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Oluşturan Kişi
                    </th>
                    <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Alınan
                    </th>
                    <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Kalan
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Teklif Fiyatı
                    </th>
                    {auth.user?.isAdmin && (
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Teklif Maliyeti
                      </th>
                    )}

                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Revize Sayısı
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                    <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                    {auth.user?.isAdmin && (
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    )} {auth.user?.isAdmin && (
                      <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                  )}
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {current.map((item, personIdx) => (
                    <tr
                      key={item._id}
                      className={personIdx % 2 === 0 ? undefined : "bg-gray-50"}
                    >
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {item.name}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.current.unvan}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {moment(item.expireTime).format("DD/MM/YYYY")}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.createdBy?.username}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {numberToMoney(item.gettedPrice || 0) + ' ' + item.currencyUnit}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {numberToMoney(item.totalPrice - item.gettedPrice) + ' ' + item.currencyUnit}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">

                        {numberToMoney(item.totalPrice) +
                          " " +
                          item.currencyUnit}
                      </td>
                      {auth.user?.isAdmin && (
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {numberToMoney(item.totalMaliyet)+' '+ item.currencyUnit}
                        </td>
                      )}

                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.revizeCount}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {item.tel2}
                      </td>

                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <span
                            onClick={() => {
                              setSelectedOffer(item);
                              setIsPriceModalOpen(true)
                            }}
                            className="text-res-600 cursor-pointer hover:text-indigo-900"
                        >
                          Alınan/Kalan
                        </span>
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <span
                          onClick={() => {
                            setSelectedOffer(item);
                            setConfirmModal(true);
                          }}
                          className="text-res-600 cursor-pointer hover:text-indigo-900"
                        >
                          Siparişe Dönüştür
                        </span>
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <span
                          onClick={() => {
                            layout.setPrintOffer(item);
                            cart.setSelectedOffer(item);
                            layout.setRevizeListModal(true);
                          }}
                          className="text-res-600 cursor-pointer hover:text-indigo-900"
                        >
                          İncele
                        </span>
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <span
                          onClick={() =>
                            makeRevize(
                              item.name,
                              item.expireTime,
                              item.current,
                              item.offers[item.offers.length - 1].products,
                              item
                            )
                          }
                          className="text-res-600 cursor-pointer hover:text-indigo-900"
                        >
                          Revize Et
                        </span>
                      </td>
                      {auth.user?.isAdmin && <td onClick={() => {
                        setSelectedOffer(item);
                        setConfirmModalDelete(true)
                      }} className="relative cursor-pointer flex items-center hover:bg-red-700 transition-all whitespace-nowrap py-4 pl-4 pr-4 text-right text-sm font-medium sm:pr-6 bg-red-400">
                        <span

                            className="text-white cursor-pointer hover:text-indigo-900"
                        >
                          Sil
                        </span>
                      </td>}

                    </tr>
                  ))}
                  {auth.user?.isAdmin && <tr
                      className={"bg-gray-50"}
                  >
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      TOPLAMLAR
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">

                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">

                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      Toplam Fiyat: {numberToMoney(totals.price)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      Toplam Maliyet: {numberToMoney(totals.maliyet)}
                    </td>

                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    </td>


                  </tr>}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <ConfirmModal
          open={confirmModalDelete}
          setOpen={() => {
            setConfirmModalDelete(false);
          }}
          submitText={"Sil"}
          onSubmit={() => handleDelete()}
          onCancel={() => {
            setConfirmModalDelete(false);
            setSelectedOffer({});
          }}
          firstText={"Silmek istediginizden eminmisiniz?"}
          secondText={
              selectedOffer?.name +
              " adli teklifi silmek istediginizden eminmisiniz?"
          }
      />
      <ConfirmModal
        open={confirmModal}
        setOpen={() => {
          setConfirmModal(false);
        }}
        submitText={"Dönüştür"}
        onSubmit={() => makeOrder()}
        onCancel={() => {
          setConfirmModal(false);
          setSelectedOffer({});
        }}
        firstText={"Siparişe dönüştürmek istediginizden eminmisiniz?"}
        secondText={
          selectedOffer?.name +
          " adli teklifi siparişe dönüştürmek istediginizden eminmisiniz?"
        }
      />

      <GetPriceModal isOpen={isPriceModalOpen} setIsOpen={() => {
        setTimeout(() => {
          setSelectedOffer({})
        },400)
        handleCurrents()
        setIsPriceModalOpen(false);
      }} offerId={selectedOffer._id} gettedPrice={selectedOffer?.gettedPrice || 0} totalOfferPrice={selectedOffer?.totalPrice} currencyUnit={selectedOffer?.currencyUnit} />
    </div>
  );
});

export default OfferList;
