import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import useStore from '../../hooks/useStore'
import authService from "../../services/authService";

const PrivateRoute = observer(({ children }) => {
    const { auth } = useStore();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        if (auth.isLogged) {
            authService.setAuthorizationToken(auth.accessToken);
            setLoading(false);
        } else {
            navigate("/auth/login");
        }
    }, []);

    if (loading) return <div />;
    else if (!auth.isLogged) return <Navigate to={"/auth/login"} />;
    else return children;
});

export default PrivateRoute;
