import moment from "moment";
import React from "react";
import { TaskiranlarLogo, Skybedd } from "../assets/images";
import numberToMoney from "../utils/numberToMoney";
import useStore from "../hooks/useStore";

const PrintScreen = ({
  products,
  current,
  currencyUnit,
  type,
  gettedPrice,
  sipno = "",
}) => {
  const { cart } = useStore();

  return (
    <div className="flex flex-col relative p-10">
      <div className="relative h-[200px]">
        <img
          src={TaskiranlarLogo}
          className="absolute top-4 left-4 w-[250px]"
        />
        <img src={Skybedd} className="absolute top-[180px] left-4 w-[250px]" />
        <span className="font-semibold absolute top-4 right-[150px]">
          PROFORMA INVOICE
        </span>

        <span className="font-bold text-sm absolute top-[60px] right-[20px]">
          {moment().format("DD/MM/YYYY")}
        </span>
        <span className="font-semibold text-sm absolute top-[80px] right-[20px]">
          {"ORDER NO : " + sipno}
        </span>
        <div className="flex flex-col border-[1px] border-black absolute w-[200px] left-[45%] top-[110px]">
          <div className="bg-[#D9D9D9] flex items-center justify-center w-full h-[50px] border-b-[1px] border-b-black">
            <span className="text-md font-semibold">
              {type === "Tedarikçi" ? "" : "SELLER"}
            </span>
          </div>
          <div className="bg-white w-full h-[80px] text-xs flex items-center justify-center">
            {type === "Tedarikçi" ? "" : "SKY YATAK VE MOBİLYA SAN,TİC,LTD,ŞTİ"}
          </div>
        </div>
        <div className="flex flex-col border-[1px] border-black absolute w-[200px] left-[73%] top-[110px]">
          <div className="bg-[#D9D9D9] flex items-center justify-center w-full h-[50px] border-b-[1px] border-b-black">
            <span className="text-md font-semibold">CUSTOMER</span>
          </div>
          <div className="bg-white w-full h-[80px] text-xs flex items-center justify-center">
            {type === "Tedarikçi" ? sipno || "" : current.unvan}
          </div>
        </div>
      </div>
      <div className="w-full mt-[60px]">
        <table
          style={{
            display: "table-row-group",
          }}
          className="w-full"
        >
          <thead
            style={{
              display: "table-row-group",
            }}
            className="bg-[#D9D9D9]"
          >
            <tr className="text-xs py-2" style={{}}>
              <th
                scope="col"
                className="text-xs py-2 font-medium border-[1px] border-black"
              >
                PICTURE
              </th>
              <th
                scope="col"
                className="text-xs py-2 font-medium border-[1px] border-black"
              >
                DESCRIPTION OF GOODS
              </th>
              <th
                scope="col"
                className="text-xs py-2 font-medium border-[1px] border-black"
              >
                PRODUCT DETAILS
              </th>
              <th
                scope="col"
                className="text-xs py-2 font-medium border-[1px] border-black"
              >
                AHSAP/METAL
              </th>
              <th
                scope="col"
                className="text-xs py-2 font-medium border-[1px] border-black"
              >
                DERI/KUMAS
              </th>
              {type !== "Tedarikçi" && (
                <th
                  scope="col"
                  className="text-xs py-2 font-medium border-[1px] border-black"
                >
                  M3
                </th>
              )}
              {type === "Admin" && (
                <th
                  scope="col"
                  className="text-xs py-2 font-medium border-[1px] border-black"
                >
                  MALIYET
                </th>
              )}
              <th
                scope="col"
                className="text-xs py-2 font-medium border-[1px] border-black"
              >
                QTY
              </th>
              <th
                scope="col"
                className="text-xs py-2 font-medium border-[1px] border-black"
              >
                UNIT PRICE {type === "Tedarikçi" ? "" : currencyUnit}
              </th>
              <th
                scope="col"
                className="text-xs py-2 font-medium border-[1px] border-black"
              >
                TOTAL PRICE {type === "Tedarikçi" ? "" : currencyUnit}
              </th>
            </tr>
          </thead>
          <tbody className={"table-row-group"} style={{}}>
            {products.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <tr
                    style={{
                      height: 90,
                      maxHeight: 90,
                    }}
                  >
                    <td className="border-[1px] border-black">
                      <img
                        className="max-w-[90px] min-w-[90px] h-[90px] max-h-[90px] min-h-[100px]"
                        style={{
                          height: "90px !important",
                          width: "90px !important",
                        }}
                        src={item.image || "testt"}
                        alt={item.name}
                      />
                    </td>
                    <td className="border-[1px] border-black">{item.name}</td>
                    <td className="border-[1px] border-black font-bold">
                      {item.aciklama}
                    </td>
                    <td className="border-[1px] border-black font-bold">
                      {item.ahsapmetal}
                    </td>
                    <td className="border-[1px] border-black font-bold">
                      {item.derikumas}
                    </td>
                    {type !== "Tedarikçi" && (
                      <td className="border-[1px] border-black">{item.m3}</td>
                    )}
                    {type === "Admin" && (
                      <td className="border-[1px] border-black">
                        {(numberToMoney(item.maliyet) || 0) +
                          " " +
                          currencyUnit}
                      </td>
                    )}
                    <td className="border-[1px] border-black">{item.count}</td>
                    <td className="border-[1px] border-black">
                      {type === "Tedarikçi"
                        ? ""
                        : numberToMoney(item.totalPrice / item.count) +
                          " " +
                          currencyUnit}
                    </td>
                    <td className="border-[1px] border-black">
                      {type === "Tedarikçi"
                        ? ""
                        : numberToMoney(item.totalPrice) + " " + currencyUnit}
                    </td>
                  </tr>
                  {index === 24 && (
                    <tr
                      className="text-xs"
                      style={{
                        height: 150,
                      }}
                    ></tr>
                  )}
                  {index === 15 && (
                    <tr
                      className="text-xs"
                      style={{
                        height: 150,
                      }}
                    ></tr>
                  )}

                  {index === 6 && (
                    <tr
                      className="text-xs"
                      style={{
                        height: 110,
                      }}
                    ></tr>
                  )}
                </React.Fragment>
              );
            })}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              {type === "Admin" && <td></td>}

              <td></td>
              {type !== "Tedarikçi" && <td></td>}
              <td className="border-[1px] border-black bg-[#D9D9D9]">TOTAL</td>
              <td className="border-[1px] border-black font-bold">
                {type === "Tedarikçi"
                  ? ""
                  : numberToMoney(
                      products.reduce((a, b) => (a += b.totalPrice), 0)
                    ) +
                    " " +
                    currencyUnit}
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              {type === "Admin" && <td></td>}

              <td></td>
              {type !== "Tedarikçi" && <td></td>}
              <td className="border-[1px] border-black bg-[#D9D9D9]">ALINAN</td>
              <td className="border-[1px] border-black font-bold">
                {type === "Tedarikçi"
                  ? ""
                  : numberToMoney(gettedPrice) + " " + currencyUnit}
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              {type === "Admin" && <td></td>}

              {type !== "Tedarikçi" && <td></td>}
              <td className="border-[1px] border-black bg-[#D9D9D9]">KALAN</td>
              <td className="border-[1px] border-black font-bold">
                {type === "Tedarikçi"
                  ? ""
                  : numberToMoney(
                      products.reduce((a, b) => (a += b.totalPrice), 0) -
                        gettedPrice
                    ) +
                    " " +
                    currencyUnit}
              </td>
            </tr>
            {type === "Admin" && (
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                {type === "Admin" && <td></td>}
                {type !== "Tedarikçi" && <td></td>}
                <td className="border-[1px] border-black bg-[#D9D9D9]">
                  TOPLAM MALIYET
                </td>
                <td className="border-[1px] border-black font-bold">
                  {type === "Tedarikçi"
                    ? ""
                    : numberToMoney(
                        products.reduce((a, b) => (a += b.maliyet * b.count), 0)
                      ) +
                      " " +
                      "TL"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {type === "Tedarikçi" ? (
        ""
      ) : (
        <div>
          <table>
            <tbody>
              <tr>
                <td className="border-[1px] border-black text-xs">
                  Delivery Cond.
                </td>
                <td className="border-[1px] border-black text-xs">EXW</td>
              </tr>
              <tr>
                <td className="border-[1px] border-black text-xs">
                  Payment Terms
                </td>
                <td className="border-[1px] border-black text-xs">
                  50%ADVANCE PAYMENT-50%AT LOADING
                </td>
              </tr>
              <tr>
                <td className="border-[1px] border-black text-xs">
                  Latest Date of Shipm.
                </td>
                <td className="border-[1px] border-black text-xs">30 DAYS</td>
              </tr>
              <tr>
                <td className="border-[1px] border-black text-xs">Bank</td>
                <td className="border-[1px] border-black text-xs">
                  GARANTİ BANKASI -İZMİR BRANCH
                </td>
              </tr>
              <tr>
                <td className="border-[1px] border-black text-xs">
                  Account Name
                </td>
                <td className="border-[1px] border-black text-xs">
                  SKY YATAK VE MOBİLYA SAN.TİC.LTD.ŞTİ
                </td>
              </tr>
              <tr>
                <td className="border-[1px] border-black text-xs">
                  Account Number Dolar
                </td>
                <td className="border-[1px] border-black text-xs">
                  TR43 0006 2001 1900 0009 0945 37
                </td>
              </tr>
              <tr>
                <td className="border-[1px] border-black text-xs">
                  Account Number Euro
                </td>
                <td className="border-[1px] border-black text-xs">
                  TR16 0006 2001 1900 0009 0945 38
                </td>
              </tr>
              <tr>
                <td className="border-[1px] border-black text-xs">
                  Swift Code
                </td>
                <td className="border-[1px] border-black text-xs">
                  TGBATRISXXX
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default PrintScreen;
